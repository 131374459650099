import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import { FormControl } from "@mui/joy";
import { InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

const LinkModal = ({ closeModel }) => {
    const [phoneNumber, setPhoneNumber] = useState('+');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');

    const handlePhoneNumberChange = (e) => {
        let value = e.target.value;
        if (!value.startsWith('+')) {
            value = '+' + value;
        }
        setPhoneNumber(value);
    };

    const handleSend = async (e) => {
        e.preventDefault();
        setMessage('');
        setIsError(false);

        if (phoneNumber.length <= 1) {
            setMessage('Phone number cannot be empty');
            setIsError(true);
            return;
        }

        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/users/call-center/complete/admission/${phoneNumber}`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            setMessage('Message sent successfully!');
            setIsError(false);
        } catch (err) {
            setMessage(err.message || "An error occurred while fetching the data");
            setIsError(true);
        }
    };

    const handleReject = async (e) => {
        e.preventDefault();
        setMessage('');
        setIsError(false);

        if (phoneNumber.length <= 1) {
            setMessage('Phone number cannot be empty');
            setIsError(true);
            return;
        }

        if (!rejectionReason) {
            setMessage('Rejection reason cannot be empty');
            setIsError(true);
            return;
        }

        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/leeds/reject`;

        const formData = new FormData();
        formData.append('phoneNumber', phoneNumber);
        formData.append('rejectionReason', rejectionReason);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Error: ${response.statusText} - ${errorData.message}`);
            }

            const data = await response.json();
            setMessage('Rejection reason sent successfully!');
            setIsError(false);
        } catch (err) {
            console.error("Error details:", err);
            setMessage(err.message || "An error occurred while sending the rejection reason");
            setIsError(true);
        }
    };

    return (
        <Modal onClose={closeModel} open={true}>
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                maxWidth: '90vw',
                width: '400px'
            }}>
                <h2>Enter a phone number</h2>
                <form onSubmit={handleSend}>
                    <input
                        type="tel"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        placeholder="Phone Number"
                        style={{ width: '100%', padding: '10px', margin: '10px 0', borderRadius: '4px', border: '1px solid #ccc' }}
                    />
                    <button
                        type="submit"
                        style={{
                            width: '100%',
                            padding: '10px',
                            backgroundColor: '#007bff',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Send
                    </button>
                </form>
                <Grid xs={12} my={4}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Reject Reason</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={rejectionReason}
                            onChange={(e) => {
                                setRejectionReason(e.target.value);
                            }}
                            displayEmpty
                            label="Reject Reason"
                        >
                            <MenuItem value=""><em>Select Option</em></MenuItem>
                            <MenuItem value="9-10 grade pupil">9-10 grade pupil</MenuItem>
                            <MenuItem value="He/she transferred to another university">He/she transferred to another university</MenuItem>
                            <MenuItem value="Just registered">Just registered</MenuItem>
                            <MenuItem value="The contract fee is expensive">The contract fee is expensive</MenuItem>
                            <MenuItem value="Parents not allowed">Parents not allowed</MenuItem>
                            <MenuItem value="Already student">Already student</MenuItem>
                            <MenuItem value="Another faculty(biology or history)">Another faculty(biology or history)</MenuItem>
                            <MenuItem value="State University or abroad">State University or abroad</MenuItem>
                            <MenuItem value="No answer (switched off, busy, no signal, Wrong number)">No answer (switched off, busy, no signal, Wrong number)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <button
                    onClick={handleReject}
                    style={{
                        width: '100%',
                        padding: '10px',
                        backgroundColor: '#dc3545',
                        color: 'white',
                        border: 'none',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        marginTop: '10px'
                    }}
                >
                    Reject
                </button>
                <div className='fw-semibold mt-2 fs-5 text-center'>
                    {message && (
                        <span style={{ color: isError ? 'red' : 'green' }}>{message}</span>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default LinkModal;