import React, { useState } from 'react';

function UserForm() {
    const [user, setUser] = useState({
        name: '',
        email: '',
        phone: '',
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser((prevState) => ({ ...prevState, [name]: value }));
    };

    return (
        <div className="user-form">
            <h2>User Info</h2>
            <form>
                <label htmlFor="name">Name:</label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    value={user.name}
                    onChange={handleChange}
                />
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    value={user.email}
                    onChange={handleChange}
                />
                <label htmlFor="phone">Phone Number:</label>
                <input
                    type="tel"
                    name="phone"
                    id="phone"
                    value={user.phone}
                    onChange={handleChange}
                />
            </form>
            <div className="user-info">
                <h2>Current User Information</h2>
                <p>Name: {user.name}</p>
                <p>Email: {user.email}</p>
                <p>Phone: {user.phone}</p>
            </div>
        </div>
    );
}

export default UserForm;
