import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/TextLayer.css";
import ContractSend from '../Contract/contractSend';
import ContractVerification from "./contractVerification";
import UploadFile from "./uploadFile";
import { useMediaQuery } from '@mui/material';
import DownloadFile from "./downloadFile";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Contracts = () => {
    const [contractInfo, setContractInfo] = useState(null);
    const [expanded, setExpanded] = useState("panel1");
    const [pdfData, setPdfData] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [nonEmptyPages, setNonEmptyPages] = useState([]);
    const [loading, setLoading] = useState(false);

    let params = (new URL(document.location)).searchParams;
    let applicantUUID = params.get("uuid");

    useEffect(() => {
        if (expanded === 'panel1') {
            const fetchPdf = async () => {
                try {
                    setLoading(true);
                    const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/files/applicant/contract/${applicantUUID}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const blob = await response.blob();
                    setPdfData(URL.createObjectURL(blob));
                } catch (error) {
                    console.error('Error fetching PDF:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchPdf();
        } else {
            setPdfData(null);
            setNumPages(null);
            setNonEmptyPages([]);
        }
    }, [expanded, applicantUUID]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        if (pdfData && numPages) {
            const checkPageText = async (pageNumber) => {
                const pageText = await getPageText(pageNumber);
                if (pageText && pageText.items && pageText.items.length > 0) {
                    setNonEmptyPages((prevPages) => {
                        const updatedPages = new Set(prevPages);
                        updatedPages.add(pageNumber);
                        return Array.from(updatedPages).sort((a, b) => a - b); // Ensure pages are in order
                    });
                }
            };

            const getPageText = async (pageNumber) => {
                const pdfDoc = await pdfjs.getDocument(pdfData).promise;
                const page = await pdfDoc.getPage(pageNumber);
                return page.getTextContent();
            };

            const checkAllPages = async () => {
                for (let i = 1; i <= numPages; i++) {
                    await checkPageText(i);
                }
            };

            checkAllPages();
        }
    }, [pdfData, numPages]);

    const handleChange = (isExpanded) => {
        setExpanded(isExpanded ? 'panel1' : false);
    };

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div className="container-mobile" style={{ width: "60%", margin: "0 auto", fontFamily: 'Arial, sans-serif' }}>
            <Accordion
                style={{
                    marginTop: "10px",
                    border: "1px solid rgb(193, 143, 99)",
                    backgroundColor: '#FFC18F63',
                    borderRadius: '8px',
                    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                    overflow: 'hidden'
                }}
                expanded={expanded === 'panel1'}
                onChange={(event, isExpanded) => handleChange(isExpanded)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                        backgroundColor: 'rgb(193, 143, 99)',
                        color: '#fff',
                        padding: '0 10px',
                    }}
                >
                    <Typography variant="h6" style={{fontWeight: 'bold'}}>View Contract</Typography>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        overflowY: 'auto',
                        overflowX: 'auto',
                        height: isMobile ? "440px" : "500px",
                        display: "flex",
                        backgroundColor: '#fff',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : pdfData ? (
                        <div className="pdf-mobile" style={{ width: isMobile ? '100%' : '800px' }}>
                            <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
                                {nonEmptyPages.map((pageNumber) => (
                                    <Page key={`page_${pageNumber}`} pageNumber={pageNumber} renderAnnotationLayer={false} width= {isMobile ? 305 : 800} />
                                ))}
                            </Document>
                        </div>
                    ) : (
                        <Typography>No contract available.</Typography>
                    )}
                </AccordionDetails>
            </Accordion>
            <ContractSend />
            <ContractVerification />
            <UploadFile />
            <DownloadFile />
        </div>
    );
};

export default Contracts;