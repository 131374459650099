import * as React from 'react';
import {Typography, Grid, TextField} from '@mui/material';
import {useNavigate} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import CopyrightForm from "./CopyrightForm";
import {useEffect, useState} from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "../../assets/css/style.css";
import UserModal from "../UserComponents/UserModal";

export default function ConfirmationForm() {
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [gender, setGender] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [passportDateOfExpire, setPassportDateOfExpire] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [emergencyContactNumber, setEmergencyContactNumber] = useState('');
    const [passportStatus, setPassportStatus] = useState('');
    const [attestatStatus, setAttestatStatus] = useState('');

    const [passportFilePath, setPassportFilePath] = useState(false);
    const [attestatFilePath, setAttestatFilePath] = useState(false);

    const [englishProficiencyFilePath, setEnglishProficiencyFilePath] = useState(false);
    const [employmentReferenceFilePath, setEmploymentReferenceFilePath] = useState(false);

    const [employmentForm, setEmploymentForm] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [bachelorDegree, setBachelorDegree] = useState("");
    const [courseType, setCourseType] = useState("");
    const [englishProficiencyLevel, setEnglishProficiencyLevel] = useState("");
    const [intake, setIntake] = useState("");
    const [workPlace, setWorkPlace] = useState("");
    const [englishProficiencyStatus, setEnglishProficiencyStatus] = useState("");
    const [employmentReferenceStatus, setEmploymentReferenceStatus] = useState("");

    const [isTransferring, setIsTransferring] = useState('');
    const [currentUniversity, setCurrentUniversity] = useState("");
    const [toCourseYear, setToCourseYear] = useState("");
    const [transcriptStatus, setTranscriptStatus] = useState("");
    const [hasSAT, setHasSAT] = useState("");
    const [satfileStatus, setSatfileStatus] = useState("");
    const [additionalFileStatus, setAdditionalFileStatus] = useState("");

    const [applicantType, setApplicantType] = useState("");
    const [registrationPlatform, setRegistrationPlatform] = useState("");
    const [applicantIDNumber, setApplicantIDNumber] = useState("");
    const [academicInfoDto, setAcademicInfoDto] = useState([]);
    const [personalInfoDto, setPersonalInfoDto] = useState([]);
    const [hasEnglishProficiencyCertificate, setHasEnglishProficiencyCertificate] = useState([])
    const [isAgreed, setIsAgreed] = useState(false);
    const [satScore, setSatScore] = useState('');
    const [status, setStatus] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const fetchAcademicInfo = async () => {
            const token = localStorage.getItem('userToken');
            const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/applicant`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (!response.ok) {
                    throw new Error(response.status);
                }
                const data = await response.json();

                if (data && data.data) {
                    setId(data.data.id);
                }

                if (data && data.data && data.data.personalInfoDto) {
                    const fetchedPersonalDetails = data.data.personalInfoDto;
                    setPersonalInfoDto(data.data.personalInfoDto);
                    setFirstName(fetchedPersonalDetails.firstName);
                    setLastName(fetchedPersonalDetails.lastName);
                    setMiddleName(fetchedPersonalDetails.middleName);
                    setGender(fetchedPersonalDetails.gender);
                    setCity(fetchedPersonalDetails.city);
                    setAddress(fetchedPersonalDetails.address);
                    setPhoneNumber(fetchedPersonalDetails.phone);
                    setEmail(fetchedPersonalDetails.email);
                    setPassportNumber(fetchedPersonalDetails.passportNumber);
                    setEmergencyContactNumber(fetchedPersonalDetails.emergencyContactNumber);
                    setPassportStatus(fetchedPersonalDetails.passportStatus);
                    setAttestatStatus(fetchedPersonalDetails.attestatStatus);
                    setDateOfBirth(fetchedPersonalDetails.dateOfBirth);
                    setPassportDateOfExpire(fetchedPersonalDetails.passportDateOfExpire);
                    setPassportFilePath(!!fetchedPersonalDetails.passportFilePath);
                    setAttestatFilePath(!!fetchedPersonalDetails.attestatFilePath);
                }

                if (data && data.data && data.data.academicInfoDto) {
                    const fetchedAcademicInfoDto = data.data.academicInfoDto;
                    const transferDetailsDto = data.data.academicInfoDto.transferDetailsDto;
                    setAcademicInfoDto(data.data.academicInfoDto);
                    setBachelorDegree(fetchedAcademicInfoDto.programDto.name);
                    setIntake(fetchedAcademicInfoDto.intake);
                    setHasEnglishProficiencyCertificate(fetchedAcademicInfoDto.hasEnglishProficiencyCertificate)
                    setEnglishProficiencyLevel(fetchedAcademicInfoDto.englishProficiencyLevel);
                    setEnglishProficiencyStatus(fetchedAcademicInfoDto.englishProficiencyStatus);
                    setCourseType(fetchedAcademicInfoDto.studyType);
                    setEnglishProficiencyFilePath(!!fetchedAcademicInfoDto.englishProficiencyFilePath);
                    if (fetchedAcademicInfoDto.studyType != null && fetchedAcademicInfoDto.studyType === "CORRESPONDENCE") {
                        setEmploymentForm(true);
                        setWorkPlace(fetchedAcademicInfoDto.workPlace);
                        setEmploymentReferenceStatus(fetchedAcademicInfoDto.employmentReferenceStatus);
                        setEmploymentReferenceFilePath(!!fetchedAcademicInfoDto.employmentReferenceFilePath);
                    }

                    if (fetchedAcademicInfoDto.transferDetailsDto === null) {
                        setIsTransferring(false)
                    } else {
                        setIsTransferring(true);
                        setCurrentUniversity(fetchedAcademicInfoDto.transferDetailsDto.currentUniversity);
                        setToCourseYear(fetchedAcademicInfoDto.transferDetailsDto.toCourseYear);
                        setTranscriptStatus(fetchedAcademicInfoDto.transferDetailsDto.transcriptStatus);
                    }

                    setHasSAT(fetchedAcademicInfoDto.hasSAT)
                    setSatScore(fetchedAcademicInfoDto.satScore)
                    setSatfileStatus(fetchedAcademicInfoDto.satfileStatus);
                    setAdditionalFileStatus(fetchedAcademicInfoDto.additionalFileStatus)

                }

            } catch (error) {
                console.log(`Error: ${error}`);
            }
        };
        fetchAcademicInfo();
    }, []);

    useEffect(() => {
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/applicant`;
        const fetchUserData = async () => {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(response.status);
                }
                const data = await response.json();
                if (data) {
                    console.log(data)
                    if (data) {
                        setStatus(data.data.applicationStatus)
                    }
                }
            } catch (error) {
                console.error(`Error: ${error}`);
            }
        }
        fetchUserData()
    }, [])

    const submitApplication = async () => {
        try {
            const token = localStorage.getItem('userToken');
            const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/submit`;
            setLoading(true);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: id,
                    personalInfoDto: personalInfoDto,
                    academicInfoDto: academicInfoDto
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to submit application');
            }
            const data = await response.json();

            localStorage.setItem("applicantIDNumber", data.data);

            return response;
        } catch (error) {
            console.error('Error submitting application:', error);
        }

    }

    const checkUserSubmitted = async () =>{
        try {
            const token = localStorage.getItem('userToken');
            const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/applicant`;

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error('Failed to submit application');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error submitting application:', error);
        }
    }

    const handleNext = async () => {
        const userSubmitted = await checkUserSubmitted();

        if (userSubmitted.data.applicantIDNumber !== null){
            return setModalOpen(true)
        }
        const apiResponse = await submitApplication();
        if (apiResponse && apiResponse.ok) {
            navigate("/application/submission-message")
        }

    };

    const handleAgreementChange = (event) => {
        setIsAgreed(event.target.checked);
    };

    const handleModal = () => {
        setModalOpen(false);
        navigate("/login")
    }

    function getStepContent() {
        return (
            <div className={`${status === 'APPROVED' && 'disabled'}`}>
                <Typography variant="h6" gutterBottom>
                    Confirmation
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="firstName"
                            name="firstName"
                            label="First name"
                            fullWidth
                            autoComplete="given-name"
                            variant="standard"
                            value={firstName}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="lastName"
                            name="lastName"
                            label="Last name"
                            fullWidth
                            autoComplete="family-name"
                            variant="standard"
                            value={lastName}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="Middle Name"
                            name="middlename:"
                            label="Middle Name"
                            fullWidth
                            variant="standard"
                            value={middleName}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="gender"
                            name="gender"
                            label="Gender"
                            fullWidth
                            variant="standard"
                            value={gender}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="dob"
                            name="dob"
                            label="Date of Birth"
                            fullWidth
                            type="date"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                            value={dateOfBirth}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="City:"
                            name="city:"
                            label="City"
                            fullWidth
                            variant="standard"
                            value={city}
                            InputProps={{
                                readOnly: true,
                            }}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="cityProvince"
                            name="cityProvince"
                            label="Address"
                            fullWidth
                            variant="standard"
                            value={address}
                            InputProps={{
                                readOnly: true,
                            }}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="email:"
                            name="Email Address"
                            label="Email Address"
                            fullWidth
                            variant="standard"
                            value={email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="serialNumber"
                            name="serialNumber"
                            label="Passport/ID Card Serial Number"
                            fullWidth
                            variant="standard"
                            value={passportNumber}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="doe"
                            name="doe"
                            label="Date of Expire"
                            fullWidth
                            type="date"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}

                            value={passportDateOfExpire}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridItem">
                        <label htmlFor="images">Passport/ID Card*</label>
                        {passportFilePath ? (
                            <p>
                                {passportStatus === "APPROVED" ? (
                                    <CheckCircleOutlineIcon sx={{color: 'green'}}/>
                                ) : (
                                    <CheckCircleOutlineIcon sx={{color: 'orange'}}/>
                                )}
                                {passportStatus === "APPROVED" ? (
                                    "Your passport file has been approved!"
                                ) : (
                                    "Your passport file has been successfully uploaded!"
                                )}
                            </p>
                        ) : (
                            <p>
                                <ErrorOutlineIcon sx={{color: 'red'}}/>
                                You haven't uploaded your passport file yet.
                            </p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridItem">
                        <label htmlFor="images">Diploma (or Certificate of Graduation)*</label>
                        {attestatFilePath ? (
                            <p>
                                {attestatStatus === "APPROVED" ? (
                                    <CheckCircleOutlineIcon sx={{color: 'green'}}/>
                                ) : (
                                    <CheckCircleOutlineIcon sx={{color: 'orange'}}/>
                                )}
                                {attestatStatus === "APPROVED" ? (
                                    "Your Diploma (or Certificate of Graduation) file has been approved!"
                                ) : (
                                    "Your Diploma (or Certificate of Graduation) file has been successfully uploaded!"
                                )}
                            </p>
                        ) : (
                            <p>
                                <ErrorOutlineIcon sx={{color: 'red'}}/>
                                You haven't uploaded a Diploma (or Certificate of Graduation) file yet.
                            </p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridItem">
                        <TextField
                            required
                            id="phoneNumber"
                            name="phoneNumber"
                            label="Phone Number"
                            fullWidth
                            type="tel"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                            value={phone}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridItem">
                        <TextField
                            required
                            id="emergencyContactNumber"
                            name="emergencyContactNumber"
                            label="Emergency contact number"
                            fullWidth
                            type="tel"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                            value={emergencyContactNumber}
                        />
                    </Grid>
                    {isTransferring && (
                        <React.Fragment>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    id="currentUniversity"
                                    name="currentUniversity"
                                    label="Current University"
                                    fullWidth
                                    variant="standard"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={currentUniversity}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    required
                                    id="toCourseYear"
                                    name="toCourseYear"
                                    label="Course Year for Transfer"
                                    fullWidth
                                    variant="standard"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={toCourseYear}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className="GridItem">
                                <label htmlFor="images">Transcript file</label>
                                {transcriptStatus ? (
                                    <p>
                                        {transcriptStatus === "APPROVED" ? (
                                            <CheckCircleOutlineIcon sx={{color: 'green'}}/>
                                        ) : (
                                            <CheckCircleOutlineIcon sx={{color: 'orange'}}/>
                                        )}
                                        {transcriptStatus === "APPROVED" ? (
                                            "Your Transcript file has been approved!"
                                        ) : (
                                            "Your Transcript file has been successfully uploaded!"
                                        )}
                                    </p>
                                ) : (
                                    <p>
                                        <ErrorOutlineIcon sx={{color: 'red'}}/>
                                        You haven't uploaded a Transcript file yet.
                                    </p>
                                )}
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            id="bachelorDegree"
                            name="bachelorDegree"
                            label="Bachelor Degree"
                            fullWidth
                            variant="standard"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={bachelorDegree}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="courseType"
                            name="courseType"
                            label="Course Type"
                            fullWidth
                            variant="standard"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={courseType}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="intake"
                            name="intake"
                            label="Intake"
                            fullWidth
                            variant="standard"
                            value={intake}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    {workPlace !== '' ?
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="workPlace"
                                name="workPlace"
                                label="Work Place"
                                fullWidth
                                variant="standard"
                                value={workPlace}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid> : null
                    }
                    {hasEnglishProficiencyCertificate === true ?
                        (<>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="ieltsScore"
                                    name="ieltsScore"
                                    label="Language proficiency level"
                                    fullWidth
                                    variant="standard"
                                    value={englishProficiencyLevel}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className="GridItem">
                                <label htmlFor="images">English Proficiency Certificate*</label>
                                {englishProficiencyFilePath ? (
                                    <p>
                                        {englishProficiencyStatus === "APPROVED" ? (
                                            <CheckCircleOutlineIcon sx={{color: 'green'}}/>
                                        ) : (
                                            <CheckCircleOutlineIcon sx={{color: 'orange'}}/>
                                        )}
                                        {englishProficiencyStatus === "APPROVED" ? (
                                            "Your English Proficiency Certificate file has been approved!"
                                        ) : (
                                            "Your English Proficiency Certificate file has been successfully uploaded!"
                                        )}
                                    </p>
                                ) : (
                                    <p>
                                        <ErrorOutlineIcon sx={{color: 'red'}}/>
                                        You haven't uploaded an English Proficiency file yet.
                                    </p>
                                )}
                            </Grid>
                        </>)
                        : null}
                    {employmentReferenceStatus !== ''
                        ? <Grid item xs={12} sm={6} className="GridItem">
                            {employmentReferenceFilePath ? (
                                <p>
                                    {employmentReferenceStatus === "APPROVED" ? (
                                        <CheckCircleOutlineIcon sx={{color: 'green'}}/>
                                    ) : (
                                        <CheckCircleOutlineIcon sx={{color: 'orange'}}/>
                                    )}
                                    {employmentReferenceStatus === "APPROVED" ? (
                                        "Your employment reference file has been approved!"
                                    ) : (
                                        "Your employment reference file has been successfully uploaded!"
                                    )}
                                </p>
                            ) : (
                                <p>
                                    <ErrorOutlineIcon sx={{color: 'red'}}/>
                                    You haven't uploaded an employment reference file yet.
                                </p>
                            )}
                        </Grid>

                        : null
                    }
                    {hasSAT && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="satScore"
                                    name="satScore"
                                    label="SAT Score"
                                    fullWidth
                                    variant="standard"
                                    value={satScore}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <React.Fragment>
                                <Grid item xs={12} sm={6} className="GridItem">
                                    <label htmlFor="images">SAT file</label>
                                    {satfileStatus ? (
                                        <p>
                                            {satfileStatus === "APPROVED" ? (
                                                <CheckCircleOutlineIcon sx={{color: 'green'}}/>
                                            ) : (
                                                <CheckCircleOutlineIcon sx={{color: 'orange'}}/>
                                            )}
                                            {satfileStatus === "APPROVED" ? (
                                                "Your SAT file has been approved!"
                                            ) : (
                                                "Your SAT file has been successfully uploaded!"
                                            )}
                                        </p>
                                    ) : (
                                        <p>
                                            <ErrorOutlineIcon sx={{color: 'red'}}/>
                                            You haven't uploaded a SAT file yet.
                                        </p>
                                    )}
                                </Grid>
                            </React.Fragment>
                        </>
                    )}
                    {additionalFileStatus ? (
                        <Grid item xs={12} sm={6} className="GridItem">
                            <label htmlFor="images">Additional file</label>
                            <p>
                                {additionalFileStatus === "APPROVED" ? (
                                    <CheckCircleOutlineIcon sx={{color: 'green'}}/>
                                ) : (
                                    <CheckCircleOutlineIcon sx={{color: 'orange'}}/>
                                )}
                                {additionalFileStatus === "APPROVED" ? (
                                    "Your Additional file has been approved!"
                                ) : (
                                    "Your Additional file has been successfully uploaded!"
                                )}
                            </p>
                        </Grid>
                    ) : null}
                </Grid>
                <Grid item xs={12} sx={{marginTop: "1rem"}}>
                    <FormControlLabel
                        control={<Checkbox color="primary" name="saveCard" value="yes"/>}
                        label={<span style={{textDecoration: 'underline', cursor: 'pointer'}}
                                     onClick={() => window.open('/privacy-policy', '_blank')}>I Agree to Privacy Policy</span>}
                        onChange={handleAgreementChange}
                    />
                </Grid>
            </div>
        );
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
            </AppBar>
            <Container component="main" maxWidth="md" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                    <Typography component="h1" variant="h4" align="center">
                        Admission Application
                    </Typography>
                    <Box sx={{mb: 2, mt: 2}}>
                        <Stepper sx={{width: '100%'}} alternativeLabel>
                            <Step key="personal-details" completed>
                                <StepLabel>Personal Details</StepLabel>
                            </Step>
                            <Step key="academic-background" completed>
                                <StepLabel>Academic Background</StepLabel>
                            </Step>
                            <Step key="confirmation">
                                <StepLabel>Confirmation</StepLabel>
                            </Step>
                        </Stepper>
                    </Box>
                    {getStepContent()}
                    {modalOpen &&  <UserModal
                        modalOpen={modalOpen}
                        handleModal={handleModal}/>
                    }
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 3}}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleNext}
                            sx={{ml: 1}}
                            disabled={!isAgreed}
                        >
                            Submit
                        </Button>
                    </Box>
                </Paper>
                <Box mt={3}>
                    <CopyrightForm/>
                </Box>
            </Container>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

        </React.Fragment>
    );
}