import React, {useState, Fragment as ReactFragment, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';
import {Typography, Grid, TextField, MenuItem} from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SaveIcon from "@mui/icons-material/Save";
import Snackbar from "@mui/joy/Snackbar";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import {useNavigate} from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CopyrightForm from "./CopyrightForm";
import HelpIcon from '@mui/icons-material/Help';
import {Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import WarningIcon from "@mui/icons-material/Warning";
import SpringModal from "../utils/SpringModal";
import { styled } from '@mui/system';
import "../../assets/css/style.css";
import QuizModel from '../UserComponents/model';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

export default function EducationBackgroundForm(props) {
    const [id, setId] = useState('');
    const [academicInfoDtoId, setAcademicInfoDtoId] = useState('');
    const [employmentForm, setEmploymentForm] = useState(false);
    const [fileUploadIelts, setFileUploadIelts] = useState(null);
    const [fileUploadEmployment, setFileUploadEmployment] = useState(null);
    const [programs, setPrograms] = useState([]);
    const [bachelorDegree, setBachelorDegree] = useState("");
    const [courseType, setCourseType] = useState("");
    const [intake, setIntake] = useState("Fall 2024-2025");
    const [workPlace, setWorkPlace] = useState("");
    const [hasEnglishProficiencyCertificate, setHasEnglishProficiencyCertificate] = useState("");
    const [englishProficiencyLevel, setEnglishProficiencyLevel] = useState("");
    const [englishProficiencyStatus, setEnglishProficiencyStatus] = useState("");
    const [englishProficiencyType, setEnglishProficiencyType] = useState("");
    const [employmentReferenceStatus, setEmploymentReferenceStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const navigate = useNavigate();

    const [isTransferring, setIsTransferring] = useState('');
    const [currentUniversity, setCurrentUniversity] = useState("");
    const [toCourseYear, setToCourseYear] = useState("");
    const [transcriptFile, setTranscriptFile] = useState(null);
    const [transcriptStatus, setTranscriptStatus] = useState("");
    const [hasSAT, setHasSAT] = useState("");
    const [satFile, setSatFile] = useState(null);
    const [satfileStatus, setSatfileStatus] = useState(null);
    const [additionalFile, setAdditionalFile] = useState(null);
    const [additionalFileStatus, setAdditionalFileStatus] = useState("");
    const [satScore, setSatScore] = useState('');

    const [open, setOpen] = useState(false);

    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const [dataSaved, setDataSaved] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [icon, setIcon] = useState(null);
    const [isFormChanged, setFormChange] = useState(false);
    const [status, setStatus] = useState('');


    useEffect(() => {
        setAllFieldsFilled(canProceed());
    }, [isTransferring, currentUniversity, toCourseYear, transcriptStatus, transcriptFile, hasEnglishProficiencyCertificate, englishProficiencyLevel, englishProficiencyType, englishProficiencyStatus, fileUploadIelts, courseType, workPlace, fileUploadEmployment, hasSAT, satScore, satFile, bachelorDegree]);

    useEffect(() => {
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/applicant`;
        const fetchUserData = async () => {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(response.status);
                }
                const data = await response.json();
                if (data) {
                    console.log(data)
                    if (data) {
                        setStatus(data.data.applicationStatus)
                    }
                }
            } catch (error) {
                console.error(`Error: ${error}`);
            }
        }
        fetchUserData()
    }, [])

    useEffect(() => {
        const fetchPrograms = async () => {
            const token = localStorage.getItem('userToken');
            const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/programs`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error(response.status);
                }

                const data = await response.json();

                if (data && data.data) {
                    setPrograms(data.data);
                }
            } catch (error) {
                console.log('Error:', error);
            }
        };
        const fetchAcademicInfo = async () => {
            const token = localStorage.getItem('userToken');
            const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/academic-info`;

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (!response.ok) {
                    throw new Error(response.status);
                }
                const data = await response.json();
                if (data && data.data) {
                    const fetchedAcademicInfoDto = data.data.academicInfoDto;
                    setId(data.data.applicantId);
                    setAcademicInfoDtoId(fetchedAcademicInfoDto.id);
                    setBachelorDegree(fetchedAcademicInfoDto.programDto.id);
                    setIntake(fetchedAcademicInfoDto.intake);
                    setCourseType(fetchedAcademicInfoDto.studyType);

                    if (fetchedAcademicInfoDto.studyType != null && fetchedAcademicInfoDto.studyType === "CORRESPONDENCE") {
                        setEmploymentForm(true);
                        setWorkPlace(fetchedAcademicInfoDto.workPlace);
                        setEmploymentReferenceStatus(fetchedAcademicInfoDto.employmentReferenceStatus);
                    }

                    if (fetchedAcademicInfoDto.transferDetailsDto === null) {
                        setIsTransferring(false)
                    } else {
                        setIsTransferring(true);
                        setCurrentUniversity(fetchedAcademicInfoDto.transferDetailsDto.currentUniversity);
                        setToCourseYear(fetchedAcademicInfoDto.transferDetailsDto.toCourseYear);
                        setTranscriptStatus(fetchedAcademicInfoDto.transferDetailsDto.transcriptStatus);
                    }
                    setHasEnglishProficiencyCertificate(fetchedAcademicInfoDto.hasEnglishProficiencyCertificate);
                    setEnglishProficiencyLevel(fetchedAcademicInfoDto.englishProficiencyLevel);
                    setEnglishProficiencyType(fetchedAcademicInfoDto.englishProficiencyType);
                    setEnglishProficiencyStatus(fetchedAcademicInfoDto.englishProficiencyStatus);

                    setHasSAT(fetchedAcademicInfoDto.hasSAT)
                    setSatScore(fetchedAcademicInfoDto.satScore)
                    setSatfileStatus(fetchedAcademicInfoDto.satfileStatus);

                    setAdditionalFileStatus(fetchedAcademicInfoDto.additionalFileStatus)

                }

            } catch (error) {
                console.log(`Error: ${error}`);
            }

        };
        fetchPrograms();
        fetchAcademicInfo();
    }, []);

    const submitAcademicInfoV1 = async () => {
        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/academic-info`;

        setLoading(true);
        handleSnackbarOpen();

        const formData = new FormData();
        formData.append('id', academicInfoDtoId);
        formData.append('isTransfer', isTransferring);
        if (isTransferring) {
            formData.append('currentUniversity', currentUniversity);
            formData.append('toCourseYear', toCourseYear);
            if (transcriptFile) formData.append('transcriptFile', transcriptFile);
            if (transcriptStatus !== null) formData.append('transcriptFileStatus', transcriptStatus);
        }
        formData.append('programId', bachelorDegree);
        formData.append('studyType', courseType);
        if (courseType === 'CORRESPONDENCE') {
            formData.append('workPlace', workPlace);
            if (fileUploadEmployment) formData.append('employmentReference', fileUploadEmployment);
            if (employmentReferenceStatus !== null) formData.append('employmentReferenceFileStatus', employmentReferenceStatus);
            console.log("workPlace work", workPlace)
        }
        console.log("workplace workplace", workPlace)
        formData.append('intake', intake);
        formData.append('hasEnglishProficiencyCertificate', hasEnglishProficiencyCertificate);
        if (hasEnglishProficiencyCertificate) {
            formData.append('englishProficiencyLevel', englishProficiencyLevel);
            formData.append('englishProficiencyType', englishProficiencyType);
            if (fileUploadIelts) formData.append('englishProficiencyFile', fileUploadIelts);
            if (englishProficiencyStatus !== null) formData.append('englishProficiencyFileStatus', englishProficiencyStatus);
        }
        formData.append('hasSAT', hasSAT);
        if (hasSAT) {
            formData.append('satScore', satScore);
            if (satFile) formData.append('satFile', satFile);
            if (satfileStatus !== null) formData.append('satFileStatus', satfileStatus);
        }
        if (additionalFile) formData.append('additionalFile', additionalFile);
        if (additionalFileStatus !== null) formData.append('additionalFileStatus', additionalFileStatus);

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });


            if (!response.ok) {
                // Handle errors based on status code
                const errorData = await response.json(); // Parse error response
                if (response.status === 400) {
                    // Display modal for 400 (Bad Request) errors
                    console.error('Bad request:', errorData);
                    // Call your modal opening function here
                    setIsModalOpen(true);// Replace with your modal logic
                    setTitle('Invalid input');
                    setDescription('Please check for errors in fields. Ensure that you\'ve entered valid info. Check for any missing fields and try again')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                } else if (response.status === 500) {
                    // Display modal or handle 500 (Internal Server Error) differently
                    console.error('Internal server error:', errorData);
                    setIsModalOpen(true);
                    setTitle('Something Went Wrong');
                    setDescription('We apologize for the inconvenience. We\'re working to resolve the issue. Please try submitting your information again in a few minutes. If the issue persists, contact us for assistance.')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                    // Call your modal opening function here (optional)
                    // handleServerError(errorData); // Replace with custom error handling
                } else {
                    // Handle unexpected errors
                    console.error('Unexpected error:', errorData);
                    setTitle('Something Went Wrong');
                    setDescription('We apologize for the inconvenience. We\'re working to resolve the issue. Please try submitting your information again in a few minutes. If the issue persists, contact us for assistance.')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                    // Consider displaying a generic error message or providing fallback behavior
                }
                throw new Error('API request failed'); // Re-throw to trigger `finally` block
            }

            const data = await response.json();
            console.log(data);

            return response;
        } catch (error) {
            console.log(`Error: ${error}`);
        } finally {
            setLoading(false);
        }
    }


    const submitAcademicInfo = async () => {
        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants`;
        const formData = new FormData();
        setLoading(true);
        handleSnackbarOpen();
        let transferDetailsDto = {};

        if (isTransferring) {
            transferDetailsDto = {
                currentUniversity: currentUniversity || null,
                toCourseYear: toCourseYear || null
            }
        }

        let academicInfoDto = {
            id: academicInfoDtoId,
            programDto: {id: bachelorDegree},
            studyType: courseType,
            intake: intake,
            hasEnglishProficiencyCertificate: hasEnglishProficiencyCertificate,
            hasSAT: hasSAT,
            englishProficiencyLevel: englishProficiencyLevel,
            englishProficiencyType: englishProficiencyType,
            workPlace: workPlace,
            transferDetailsDto: transferDetailsDto,
            satScore: satScore
        }
        let dataToSend = {
            id: id,
            academicInfoDto,
        };
        console.log(JSON.stringify(dataToSend));
        formData.append('applicantDto', JSON.stringify(dataToSend));
        if (fileUploadIelts) formData.append('englishProficiencyFile', fileUploadIelts);
        if (fileUploadEmployment) formData.append('employmentReferenceFile', fileUploadEmployment);

        if (transcriptFile) formData.append('transcriptFile', transcriptFile);
        if (additionalFile) formData.append('additionalFile', additionalFile);
        if (satFile) formData.append('satFile', satFile);

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                // Handle errors based on status code
                const errorData = await response.json(); // Parse error response
                if (response.status === 400) {
                    // Display modal for 400 (Bad Request) errors
                    console.error('Bad request:', errorData);
                    // Call your modal opening function here
                    setIsModalOpen(true);// Replace with your modal logic
                    setTitle('Invalid input');
                    setDescription('Please check for errors in fields. Ensure that you\'ve entered valid info. Check for any missing fields and try again')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                } else if (response.status === 500) {
                    // Display modal or handle 500 (Internal Server Error) differently
                    console.error('Internal server error:', errorData);
                    setIsModalOpen(true);
                    setTitle('Something Went Wrong');
                    setDescription('We apologize for the inconvenience. We\'re working to resolve the issue. Please try submitting your information again in a few minutes. If the issue persists, contact us for assistance.')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                    // Call your modal opening function here (optional)
                    // handleServerError(errorData); // Replace with custom error handling
                } else {
                    // Handle unexpected errors
                    console.error('Unexpected error:', errorData);
                    setTitle('Something Went Wrong');
                    setDescription('We apologize for the inconvenience. We\'re working to resolve the issue. Please try submitting your information again in a few minutes. If the issue persists, contact us for assistance.')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                    // Consider displaying a generic error message or providing fallback behavior
                }
                throw new Error('API request failed'); // Re-throw to trigger `finally` block
            }

            const data = await response.json();
            console.log(data);

        } catch (error) {
            console.log(`Error: ${error}`);
        } finally {
            setLoading(false);
        }
    }

    const {getRootProps: getRootProps1, getInputProps: getInputProps1} = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf',
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const fileType = file.type
            const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            if (validTypes.includes(fileType)) {
                setFileUploadIelts(file);
                handleFormChange();
            } else {
                setIsModalOpen(true);
                setTitle('Invalid file type');
                setDescription('File was rejected. Invalid file format. Only JPEG, PNG, PDF file types are accepted. Please try again');
                setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
            }
        },
    });

    const {getRootProps: getRootProps2, getInputProps: getInputProps2} = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf',
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const fileType = file.type
            const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            if (validTypes.includes(fileType)) {
                setFileUploadEmployment(file);
                handleFormChange();
            } else {
                setIsModalOpen(true);
                setTitle('Invalid file type');
                setDescription('File was rejected. Invalid file format. Only JPEG, PNG, PDF file types are accepted. Please try again');
                setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
            }
        },
    });

    const {getRootProps: getRootPropsTranscript, getInputProps: getInputPropsTranscript} = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf',
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const fileType = file.type
            const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            if (validTypes.includes(fileType)) {
                setTranscriptFile(file);
                handleFormChange();
            } else {
                setIsModalOpen(true);
                setTitle('Invalid file type');
                setDescription('File was rejected. Invalid file format. Only JPEG, PNG, PDF file types are accepted. Please try again');
                setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
            }
        },
    });

    const {
        getRootProps: getRootPropsAdditionalCertificate,
        getInputProps: getInputPropsAdditionalCertificate
    } = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf',
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const fileType = file.type
            const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            if (validTypes.includes(fileType)) {
                setAdditionalFile(file);
                handleFormChange();
            } else {
                setIsModalOpen(true);
                setTitle('Invalid file type');
                setDescription('File was rejected. Invalid file format. Only JPEG, PNG, PDF file types are accepted. Please try again');
                setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
            }
        },
    });

    const {getRootProps: getRootPropsSatScore, getInputProps: getInputPropsSatScore} = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf',
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const fileType = file.type
            const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            if (validTypes.includes(fileType)) {
                setSatFile(file);
                handleFormChange();
            } else {
                setIsModalOpen(true);
                setTitle('Invalid file type');
                setDescription('File was rejected. Invalid file format. Only JPEG, PNG, PDF file types are accepted. Please try again');
                setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
            }
        },
    });

    const handleDeleteFile1 = () => {
        setFileUploadIelts(null);
    };
    const handleDeleteFile2 = () => {
        setFileUploadEmployment(null);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleDeleteTranscript = () => {
        setTranscriptFile(null);
    };

    const handleDeleteAdditionalCertificateFile = () => {
        setAdditionalFile(null);
    };

    const handleDeleteSatScoreFile = () => {
        setSatFile(null);
    };

    const handleNext = async () => {
        if (isFormChanged) {
            const apiResponse = await submitAcademicInfoV1();
            if (apiResponse && apiResponse.ok) {
                navigate("/application/confirmation")
            }
        } else {
            navigate("/application/confirmation")
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFormChange = () => {
        setFormChange(true);
    }

    const validateIsTransferring = () => {
        if (isTransferring === true) {
            if (transcriptStatus === null) {
                return currentUniversity !== '' && toCourseYear !== '' && transcriptFile !== null
            } else {
                return currentUniversity !== '' && toCourseYear !== '';
            }
        } else {
            return bachelorDegree !== '' && isTransferring === false
        }
    }

    const validateEnglishProficiency = () => {
        if (hasEnglishProficiencyCertificate === true) {
            if (englishProficiencyStatus === null) {
                return englishProficiencyLevel !== '' && englishProficiencyType !== '' && fileUploadIelts !== null
            } else {
                return englishProficiencyLevel !== '' && englishProficiencyType !== '';
            }
        } else {
            return bachelorDegree !== '' && hasEnglishProficiencyCertificate === false
        }
    }

    const validateCourseType = () => {
        if (courseType === 'FULLTIME') {
            return courseType !== ''
        }
        if (courseType === 'CORRESPONDENCE') {
            return workPlace !== '' && fileUploadEmployment !== null
        }
    }

    const validateSat = () => {
        if (hasSAT === true) {
            console.log("-----Check----1")
            // user should fill in both satScore and satFile
            console.log("----Check status: " + (satfileStatus === null));
            console.log("SAT FILE STATUS: " + satfileStatus);
            if (satfileStatus === null) {
                console.log("---Check 2: " + (satScore !== '' && satFile !== null))
                return satScore !== '' && satFile !== null;
            } else {
                console.log("---Check 2: " + satScore !== '')
                return satScore !== '';
            }
        } else {
            // If user does not have sat, then only check bachelorDegree
            console.log("---Check----2")
            return bachelorDegree !== '' && hasSAT === false;
        }
    }

    const canProceed = () => {
        // console.log("Transfer: " + validateIsTransferring())
        // console.log("English: " + validateEnglishProficiency())
        // console.log("Course type: " + courseType)
        // console.log("SAT: " + validateSat())
        return validateIsTransferring() &&
            validateEnglishProficiency() &&
            courseType !== '' &&
            validateSat()
    }

    const handleModalClose = () => {
        // Reset state and navigate away (replace '/login' with your actual login route)
        setIsModalOpen(false);
    };

    const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
        [theme.breakpoints.only('xs')]: {
            whiteSpace: 'normal',
            minHeight: 48,
            lineHeight: '1.4em',
        },
    }));

    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    const closeModal = () => {
        setIsOpen(!isOpen);
    }

    function getStepContent() {
        return (
            <div className={`${status === 'APPROVED' && 'disabled'}`}>
                <Grid item xs={12} sm={6} sx={{marginBottom: '1rem'}}>
                    <TextField
                        required
                        id="isTransferring"
                        name="isTransferring"
                        label="Are you transferring from another university?"
                        fullWidth
                        select
                        variant="standard"
                        value={isTransferring}
                        onChange={e => {
                            setIsTransferring(e.target.value);
                            handleFormChange();
                        }}
                    >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </TextField>
                </Grid>
                {isTransferring ?
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} sx={{marginBottom: '1rem'}}>
                            <TextField
                                required
                                id="currentUniversity"
                                name="currentUniversity"
                                label="Enter your current University"
                                fullWidth
                                variant="standard"
                                value={currentUniversity}
                                onChange={e => {
                                    setCurrentUniversity(e.target.value);
                                    handleFormChange();
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{marginBottom: '1rem'}}>
                            <TextField
                                required
                                id="toCourseYear"
                                name="toCourseYear"
                                label="Select Course Year for Transfer"
                                fullWidth
                                select
                                variant="standard"
                                value={toCourseYear}
                                onChange={e => {
                                    setToCourseYear(e.target.value);
                                    handleFormChange();
                                }}
                            >
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} className="GridItem" sx={{marginBottom: '1rem'}}>
                            <div className="inputWithLabel">
                                <label htmlFor="transcript">Transcript*</label>
                                <br/>
                                <p style={{color: "red", fontSize: "15px"}}>(Only JPEG, PNG, PDF file types are
                                    accepted)</p>
                                {(transcriptStatus !== "SUBMITTED" && transcriptStatus !== "APPROVED") && (
                                    <div {...getRootPropsTranscript()} className="dropzone">
                                        <input {...getInputPropsTranscript()} required/>
                                        {transcriptFile ? (
                                            <div>
                                                {transcriptFile.type.startsWith('image/') ? (
                                                    <img
                                                        src={URL.createObjectURL(transcriptFile)}
                                                        alt="Uploaded"
                                                        className="uploadedImage"
                                                    />
                                                ) : (
                                                    <div className="fileInfo">
                                                        <p>{transcriptFile.name}</p>
                                                    </div>
                                                )}
                                                <button className="deleteButton" onClick={handleDeleteTranscript}>
                                                    <DeleteIcon/>
                                                </button>
                                            </div>
                                        ) : (
                                            <p>Drag & drop or click to select a file</p>
                                        )}
                                    </div>
                                )}
                                {transcriptStatus === "SUBMITTED" && (
                                    <p><CheckCircleOutlineIcon sx={{color: 'orange'}}/> Your submitted file is waiting
                                        for confirmation.</p>
                                )}
                                {transcriptStatus === "APPROVED" && (
                                    <p><CheckCircleOutlineIcon sx={{color: 'green'}}/> Your submitted file has been
                                        approved.</p>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                    :
                    null
                }
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="courseType"
                            name="courseType"
                            label="Course Type"
                            fullWidth
                            select
                            variant="standard"
                            value={courseType}
                            onChange={e => {
                                setCourseType(e.target.value);
                                handleFormChange();
                            }}
                        >
                            <MenuItem value="CORRESPONDENCE" onClick={() => setEmploymentForm(true)}>Part Time
                                (Заочный)
                            </MenuItem>
                            <MenuItem value="FULLTIME" onClick={() => setEmploymentForm(false)}>Full Time
                                (Очный)
                            </MenuItem>
                            <MenuItem value="EVENING" onClick={() => setEmploymentForm(true)}>
                                Evening
                            </MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="intake"
                            name="intake"
                            label="Intake"
                            fullWidth
                            select
                            variant="standard"
                            value={intake}
                            onChange={e => {
                                setIntake(e.target.value);
                                handleFormChange();
                            }}
                        >
                            <MenuItem value="Fall 2024-2025">Fall 2024-2025</MenuItem>
                        </TextField>
                    </Grid>
                    {employmentForm ? <Grid item xs={12} sm={6}>
                        <TextField
                            id="workPlace"
                            name="workPlace"
                            label="Work Place(Optional)"
                            fullWidth
                            autoComplete="work-place"
                            variant="standard"
                            value={workPlace}
                            onChange={e => {
                                setWorkPlace(e.target.value);
                                handleFormChange();
                            }}
                        />
                    </Grid> : null}
                    {employmentForm && (
                        <Grid item xs={12} sm={6} className="GridItem">
                            <div className="inputWithLabel">
                                <label htmlFor="images">Employment Reference (Optional)</label>
                                <br/>
                                <p style={{color: "red", fontSize: "15px"}}>(Only JPEG, PNG, PDF file types are
                                    accepted)</p>
                                {(employmentReferenceStatus !== "SUBMITTED" && employmentReferenceStatus !== "APPROVED") && (
                                    <div {...getRootProps2()} className="dropzone">
                                        <input {...getInputProps2()}/>
                                        {fileUploadEmployment ? (
                                            <div>
                                                {fileUploadEmployment.type.startsWith('image/') ? (
                                                    <img
                                                        src={URL.createObjectURL(fileUploadEmployment)}
                                                        alt="Uploaded"
                                                        className="uploadedImage"
                                                    />
                                                ) : (
                                                    <div className="fileInfo">
                                                        <p>{fileUploadEmployment.name}</p>
                                                    </div>
                                                )}
                                                <button className="deleteButton" onClick={handleDeleteFile2}>
                                                    <DeleteIcon/>
                                                </button>
                                            </div>
                                        ) : (
                                            <p>Drag & drop or click to select a file</p>
                                        )}
                                    </div>
                                )}
                                {employmentReferenceStatus === "SUBMITTED" && (
                                    <p><CheckCircleOutlineIcon sx={{color: 'orange'}}/> Your submitted file is waiting
                                        for confirmation.</p>
                                )}
                                {employmentReferenceStatus === "APPROVED" && (
                                    <p><CheckCircleOutlineIcon sx={{color: 'green'}}/> Your submitted file has been
                                        approved.</p>
                                )}
                            </div>
                        </Grid>
                    )}
                    <Grid style={{display: "flex", alignItems: "center", justifyContent: "center"}} item xs={12} sm={12}>
                        <TextField
                            required
                            id="bachelorDegree"
                            name="bachelorDegree"
                            label="Choose Bachelor Degree"
                            fullWidth
                            select
                            variant="standard"
                            value={bachelorDegree}
                            onChange={e => {
                                setBachelorDegree(e.target.value);
                                handleFormChange();
                            }}
                        >
                            {courseType === "FULLTIME"
                                ?
                                programs.map((program, i) => (
                                    <CustomMenuItem key={i} value={program.id}>{program.name}</CustomMenuItem>
                                ))
                                :
                                (courseType === "EVENING" && programs.some(program => program.id === 3))
                                    ?
                                    programs.filter(program => program.id === 3)
                                        .map((program, i) => (
                                            <CustomMenuItem key={i} value={program.id}>{program.name}</CustomMenuItem>
                                        ))
                                    :
                                    programs.filter(program => [1, 5, 2].includes(program.id))
                                        .map((program, i) => (
                                            <CustomMenuItem key={i} value={program.id}>{program.name}</CustomMenuItem>
                                        ))
                            }
                        </TextField>
                        <span onClick={openModal}
                              style={{marginLeft: '5px', marginTop: "15px", cursor: 'pointer'}}><TipsAndUpdatesIcon  style={{ fontSize: '1.8rem', color: "orange"}}/></span>
                        {isOpen && <QuizModel closeModal={closeModal}/>}
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{marginBottom: '1rem'}}>
                        <TextField
                            required
                            id="hasEnglishProficiencyCertificate"
                            name="hasEnglishProficiencyCertificate"
                            label="Do you have English Profiency Certificate"
                            fullWidth
                            select
                            variant="standard"
                            value={hasEnglishProficiencyCertificate}
                            onChange={e => {
                                setHasEnglishProficiencyCertificate(e.target.value);
                                handleFormChange();
                            }}
                        >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </TextField>
                    </Grid>
                    {hasEnglishProficiencyCertificate && (
                        <Grid container spacing={3} style={{marginLeft: '1rem'}}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    id="englishCertificate"
                                    name="englishCertificate"
                                    label="English Certificate"
                                    fullWidth
                                    select
                                    variant="standard"
                                    value={englishProficiencyType}
                                    onChange={e => {
                                        setEnglishProficiencyType(e.target.value);
                                        handleFormChange();
                                    }}
                                >
                                    <MenuItem value="IELTS">IELTS</MenuItem>
                                    <MenuItem value="TOEFL">TOEFL</MenuItem>
                                    <MenuItem value="CEFR">CEFR</MenuItem>
                                    <MenuItem value="DUOLINGO">Duolingo</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} container alignItems="center">
                                <Grid item xs={10}>
                                    <TextField
                                        required
                                        id="englishProficiencyLevel"
                                        name="englishProficiencyLevel"
                                        label="Language proficiency levels"
                                        fullWidth
                                        select
                                        variant="standard"
                                        value={englishProficiencyLevel}
                                        onChange={e => {
                                            setEnglishProficiencyLevel(e.target.value);
                                            handleFormChange();
                                        }}
                                    >
                                        <MenuItem value="A1">A1</MenuItem>
                                        <MenuItem value="A2">A2</MenuItem>
                                        <MenuItem value="B1">B1</MenuItem>
                                        <MenuItem value="B2">B2</MenuItem>
                                        <MenuItem value="C1">C1</MenuItem>
                                        <MenuItem value="C2">C2</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item xs={2} sx={{marginTop: "1rem", display: 'flex', justifyContent: 'flex-end'}}>
                                    <HelpIcon onClick={handleOpen}
                                              sx={{color: 'orange', fontSize: '2rem', cursor: "pointer"}}/>
                                </Grid>
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 800,
                                        maxWidth: '95%',
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 4,
                                        overflowY: 'auto',
                                        maxHeight: '80vh'
                                    }}>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            English Proficiency Levels
                                        </Typography>
                                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>English Certificates</TableCell>
                                                            <TableCell>Level</TableCell>
                                                            <TableCell>Score</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell rowSpan={6}>IELTS</TableCell>
                                                            <TableCell>A1</TableCell>
                                                            <TableCell>1.0-1.5</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>A2</TableCell>
                                                            <TableCell>3.0-3.5</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>B1</TableCell>
                                                            <TableCell>4.0-5.0</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>B2</TableCell>
                                                            <TableCell>5.5-6.5</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>C1</TableCell>
                                                            <TableCell>7.0-8.0</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>C2</TableCell>
                                                            <TableCell>8.5-9.0</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell rowSpan={6}>TOEFL</TableCell>
                                                            <TableCell>A1</TableCell>
                                                            <TableCell>-</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>A2</TableCell>
                                                            <TableCell>-</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>B1</TableCell>
                                                            <TableCell>42-71</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>B2</TableCell>
                                                            <TableCell>72-94</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>C1</TableCell>
                                                            <TableCell>95-113</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>C2</TableCell>
                                                            <TableCell>114-120</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell rowSpan={6}>Duolingo</TableCell>
                                                            <TableCell>A1</TableCell>
                                                            <TableCell>10-55</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>A2</TableCell>
                                                            <TableCell>10-55</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>B1</TableCell>
                                                            <TableCell>60-95</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>B2</TableCell>
                                                            <TableCell>100 - 125</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>C1</TableCell>
                                                            <TableCell>130 - 150</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>C2</TableCell>
                                                            <TableCell>155 - 160</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Typography>
                                    </Box>
                                </Modal>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{marginBottom: '1rem'}} className="GridItem">
                                <div className="inputWithLabel">
                                    <label htmlFor="images">English Proficiency Certificate*</label>
                                    <br/>
                                    <p style={{color: "red", fontSize: "15px"}}>(Only JPEG, PNG, PDF file types are
                                        accepted)</p>
                                    {(englishProficiencyStatus !== "SUBMITTED" && englishProficiencyStatus !== "APPROVED") && (
                                        <div {...getRootProps1()} className="dropzone">
                                            <input {...getInputProps1()} required/>
                                            {fileUploadIelts ? (
                                                <div>
                                                    {fileUploadIelts.type.startsWith('image/') ? (
                                                        <img
                                                            src={URL.createObjectURL(fileUploadIelts)}
                                                            alt="Uploaded"
                                                            className="uploadedImage"
                                                        />
                                                    ) : (
                                                        <div className="fileInfo">
                                                            <p>{fileUploadIelts.name}</p>
                                                        </div>
                                                    )}
                                                    <button className="deleteButton" onClick={handleDeleteFile1}>
                                                        <DeleteIcon/>
                                                    </button>
                                                </div>
                                            ) : (
                                                <p>Drag & drop or click to select a file</p>
                                            )}
                                        </div>
                                    )}
                                    {englishProficiencyStatus === "SUBMITTED" && (
                                        <p><CheckCircleOutlineIcon sx={{color: 'orange'}}/> Your submitted file is
                                            waiting for confirmation.</p>
                                    )}
                                    {englishProficiencyStatus === "APPROVED" && (
                                        <p><CheckCircleOutlineIcon sx={{color: 'green'}}/> Your submitted file has been
                                            approved</p>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12} sm={6} sx={{marginTop: '1rem', marginBottom: '1rem'}}>
                    <TextField
                        required
                        id="hasSAT"
                        name="hasSAT"
                        label="Do you have SAT?"
                        fullWidth
                        select
                        variant="standard"
                        value={hasSAT}
                        onChange={e => {
                            setHasSAT(e.target.value);
                            handleFormChange();
                        }}
                    >
                        <MenuItem value={true}>Yes</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </TextField>
                </Grid>
                {
                    hasSAT ? <>
                            <Grid item xs={12} sm={6} sx={{marginBottom: '1rem'}}>
                                <TextField
                                    required
                                    id="satScore"
                                    name="satScore"
                                    label="SAT Score"
                                    fullWidth
                                    variant="standard"
                                    value={satScore}
                                    onChange={e => {
                                        setSatScore(e.target.value);
                                        handleFormChange();
                                    }}
                                />
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} sx={{marginBottom: '1rem'}}>
                                    <div className="inputWithLabel">
                                        <label htmlFor="images">SAT Certificate*</label>
                                        <br/>
                                        <p style={{color: "red", fontSize: "15px"}}>(Only JPEG, PNG, PDF file types are
                                            accepted)</p>
                                        {(satfileStatus !== "SUBMITTED" && satfileStatus !== "APPROVED") && (
                                            <div {...getRootPropsSatScore()} className="dropzone">
                                                <input {...getInputPropsSatScore()} />
                                                {satFile ? (
                                                    <div>
                                                        {satFile.type.startsWith('image/') ? (
                                                            <img
                                                                src={URL.createObjectURL(satFile)}
                                                                alt="Uploaded"
                                                                className="uploadedImage"
                                                            />
                                                        ) : (
                                                            <div className="fileInfo">
                                                                <p>{satFile.name}</p>
                                                            </div>
                                                        )}
                                                        <button className="deleteButton"
                                                                onClick={handleDeleteSatScoreFile}>
                                                            <DeleteIcon/>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <p>Drag & drop or click to select a file</p>
                                                )}
                                            </div>
                                        )}
                                        {satfileStatus === "SUBMITTED" && (
                                            <p>
                                                <CheckCircleOutlineIcon sx={{color: 'orange'}}/> Your submitted file is
                                                waiting for confirmation.
                                            </p>
                                        )}
                                        {satfileStatus === "APPROVED" && (
                                            <p>
                                                <CheckCircleOutlineIcon sx={{color: 'green'}}/> Your submitted file has
                                                been approved.
                                            </p>
                                        )}
                                    </div>
                                </Grid>

                            </Grid></>
                        : null
                }
                <Grid item xs={12} sm={6} className="GridItem">
                    <div className="inputWithLabel">
                        <label htmlFor="images">Additional Certificate(Optional)</label>
                        <br/>
                        <p style={{color: "red", fontSize: "15px"}}>(Only JPEG, PNG, PDF file types are accepted)</p>
                        {(additionalFileStatus !== "SUBMITTED" && additionalFileStatus !== "APPROVED") && (
                            <div {...getRootPropsAdditionalCertificate()} className="dropzone">
                                <input {...getInputPropsAdditionalCertificate()} required/>
                                {additionalFile ? (
                                    <div>
                                        {additionalFile.type.startsWith('image/') ? (
                                            <img
                                                src={URL.createObjectURL(additionalFile)}
                                                alt="Uploaded"
                                                className="uploadedImage"
                                            />
                                        ) : (
                                            <div className="fileInfo">
                                                <p>{additionalFile.name}</p>
                                            </div>
                                        )}
                                        <button className="deleteButton"
                                                onClick={handleDeleteAdditionalCertificateFile}>
                                            <DeleteIcon/>
                                        </button>
                                    </div>
                                ) : (
                                    <p>Drag & drop or click to select a file</p>
                                )}
                            </div>
                        )}
                        {additionalFileStatus === "SUBMITTED" && (
                            <p><CheckCircleOutlineIcon sx={{color: 'orange'}}/> Your submitted file is waiting for
                                confirmation.</p>
                        )}
                        {additionalFileStatus === "APPROVED" && (
                            <p><CheckCircleOutlineIcon sx={{color: 'green'}}/> Your submitted file has been approved.
                            </p>
                        )}
                    </div>
                </Grid>
            </div>
        );
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
            </AppBar>
            <Container component="main" maxWidth="md" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                    <Typography component="h1" variant="h4" align="center">
                        Admission Application
                    </Typography>
                    <Box sx={{mb: 2, mt: 2}}>
                        <Stepper sx={{width: '100%'}} alternativeLabel>
                            <Step key="personal-details" completed>
                                <StepLabel>Personal Details</StepLabel>
                            </Step>
                            <Step key="academic-background">
                                <StepLabel>Academic Background</StepLabel>
                            </Step>
                            <Step key="confirmation">
                                <StepLabel>Confirmation</StepLabel>
                            </Step>
                        </Stepper>
                    </Box>
                    {getStepContent()}
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 3}}>
                        {/*<Button*/}
                        {/*    variant="contained"*/}
                        {/*    color="success"*/}
                        {/*    size="small"*/}
                        {/*    startIcon={<SaveIcon/>}*/}
                        {/*    onClick={submitAcademicInfo}*/}
                        {/*    sx={{ml: 1}}*/}
                        {/*>*/}
                        {/*    Save*/}
                        {/*</Button>*/}
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleNext}
                            sx={{ml: 1}}
                            disabled={!allFieldsFilled}
                        >
                            Next
                        </Button>
                    </Box>
                </Paper>
                <Box mt={3}>
                    <CopyrightForm/>
                </Box>
            </Container>
            <Snackbar
                variant="solid"
                color="success"
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                autoHideDuration={2000}
                startDecorator={<PlaylistAddCheckCircleRoundedIcon/>}
            >
                Your data has been saved successfully.
            </Snackbar>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <SpringModal
                open={isModalOpen}
                handleClose={handleModalClose}
                title={title}
                description={description}
                icon={icon}
            />
        </React.Fragment>
    );
}