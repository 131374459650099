import Typography from "@mui/material/Typography";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CopyrightForm from "./CopyrightForm.js";

const styles = {
    container: {
        padding: '20px',
        maxWidth: '640px',
        margin: 'auto',
        textAlign: 'center',
    },
    h5: {
        marginBottom: '14px',
        fontSize: '2rem',
        color: '#3498db',
        fontWeight: 'bold',
    },
    subtitle1: {
        fontSize: '1.2rem',
        lineHeight: '1.6',
        color: '#555',
    },
};
export default function SubmissionMessageForm() {
    const applicantIdNumber = localStorage.getItem("applicantIDNumber")
    function getStepContent() {
        return (
            <React.Fragment>
                <div id="smooth-container" style={styles.container}>
                    <Typography variant="h5" style={styles.h5} gutterBottom>
                        Thank you for the submission.
                    </Typography>
                    <Typography variant="subtitle1" style={styles.subtitle1}>
                        Your application has been assigned the reference number <strong>#{applicantIdNumber}</strong>.
                        An email containing your application confirmation has been sent.
                        You will receive further notifications once your application has undergone the approval process.
                    </Typography>
                    <div className="questions-text">
                        <Typography variant="body2" color="text.secondary">
                            If you have any questions, please communicate using your reference number.
                        </Typography>
                    </div>
                    <div className="contacts">
                        <a className="contacts-email" target="_blank" href="mailto:admission2024@millatumidi.uz">
                            <MailOutlineIcon sx={styles.contactIcon}/>
                            admission2024@millatumidi.uz
                        </a>
                        <a target="_blank" href="tel:+998 71 200 03 06">
                            <PhoneIcon sx={styles.contactIcon}/>
                            +998 71 200 03 06
                        </a>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
            </AppBar>
            <Container component="main" maxWidth="md" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                    <Typography component="h1" variant="h4" align="center">
                        Admission Application
                    </Typography>
                    <Box sx={{mb: 2, mt: 2}}>
                        <Stepper sx={{width: '100%'}} alternativeLabel>
                            <Step key="personal-details" completed>
                                <StepLabel>Personal Details</StepLabel>
                            </Step>
                            <Step key="academic-background" completed>
                                <StepLabel>Academic Background</StepLabel>
                            </Step>
                            <Step key="confirmation" completed>
                                <StepLabel>Confirmation</StepLabel>
                            </Step>
                        </Stepper>
                    </Box>
                    {getStepContent()}
                </Paper>
                <Box mt={3}>
                    <CopyrightForm/>
                </Box>
            </Container>
        </React.Fragment>

    );
}