import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import * as React from "react";

const UserModal = ({modalOpen, handleModal}) => {
  return (
    <Dialog

        sx= {{
            maxWidth: '500px',
            margin: 'auto'}}
        open={modalOpen}
        onClose={handleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
            <ErrorOutlineIcon />
        {" You have already been registered"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Please wait for a response from the admissions office.
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
                If you need to make changes, please contact the admissions office:
                <br/>
                <a style={{display: "inline-block", marginTop: "5px"}} target="_blank" href="tel:+998 71 200 03 06">
                    <PhoneIcon/> +998 71 200 03 06
                </a>
                <br/>
                <a style={{display: "inline-block", marginTop: "5px"}} target="_blank" href="mailto:admission2024@millatumidi.uz">
                    <MailOutlineIcon/>
                    admission2024@millatumidi.uz
                </a>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleModal}>OK</Button>
        </DialogActions>
    </Dialog>
  )
}

export default UserModal