import React from 'react';
import muLogo from '../../assets/images/single-logo.svg';
import { Typography } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const ContractSend = ({ view }) => {
    return (
        <div style={{marginTop: "5px", display: 'flex', flexDirection: 'column', alignItems: 'center'}}
             className="banner">
            <div className="banner-content">
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <img className="mu-mobile-logo" style={{width: "25px", height: "25px", marginRight: '10px'}} src={muLogo}
                         alt="MU University Logo"/>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <Typography variant="h6" className="university-name university-name-mobile" style={{margin: 0, fontWeight: 'bold'}}>MU
                            University /</Typography>
                        <div>
                            <Typography variant="h6" className="university-name-mobile"
                                        style={{marginLeft: "10px", color: 'green'}}>Contract Signed</Typography>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner-content" style={{textAlign: 'center'}}>
                <Typography style={{color: "green"}} variant="h6">Verified</Typography>
                <VerifiedIcon sx={{fontSize: 25, color: 'green'}} style={{marginLeft: "5px"}}/>
            </div>
        </div>
    );
};
export default ContractSend;
