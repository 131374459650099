import * as React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Import Material UI icon

const Fade = React.forwardRef(function Fade(props, ref) {
    const { children, in: open, onClick, onEnter, onExited, ownerState, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center content vertically
    justifyContent: 'center', // Center content horizontally
};

export default function SpringModal({ open, handleClose, title, description, icon}) {
    return (
        <Modal
            aria-labelledby="spring-modal-title"
            aria-describedby="spring-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition // Close after the transition animation is complete
            BackdropComponent={Backdrop} // Specify custom Backdrop component
            BackdropProps={{
                timeout: 500, // Set custom timeout for backdrop animation
            }}
        >
            <Fade in={open}>
                <Box sx={modalStyle}>
                    {/*<CheckCircleIcon sx={{ fontSize: 72, color: 'green' }} />*/}
                    {icon && <div>{icon}</div>}
                    <Typography id="spring-modal-title" variant="h5" component="h2" sx={{ mt: 2 }}>
                        {title}
                    </Typography>
                    <Typography id="spring-modal-description" sx={{ mt: 2 }}>
                        {description}
                    </Typography>
                    <Button onClick={handleClose} variant="contained" sx={{ mt: 3 }}>
                        OK
                    </Button>
                </Box>
            </Fade>
        </Modal>
    );
}
