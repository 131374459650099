import React, {useState, useEffect, useMemo} from 'react';
import {Link, Navigate, redirect} from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {Document, Page} from "react-pdf";
import {pdfjs} from 'react-pdf';
import Button from "@mui/material/Button";
import {Card, CardContent, Typography, IconButton, Divider, InputLabel, NativeSelect} from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import WarningIcon from "@mui/icons-material/Warning";
import Snackbar from "@mui/joy/Snackbar";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SpringModal from "../utils/SpringModal";
import {border, padding, styled} from "@mui/system";
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import {useSpring, animated} from "@react-spring/web";
import {FormControl, Textarea} from "@mui/joy";
import FileModal from './FileModal';
import Input from '@mui/joy/Input';
import {Search} from '@mui/icons-material';
import GetAppIcon from "@mui/icons-material/GetApp";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DownloadIcon from '@mui/icons-material/Download';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from "@mui/icons-material/Delete";
import {useDropzone} from 'react-dropzone';

const token = localStorage.getItem('userToken');
if (!token) {
    redirect('/login');
}

const Fade = React.forwardRef(function Fade(props, ref) {
    const {children, in: open, onClick, onEnter, onExited, ownerState, ...other} = props;
    const style = useSpring({
        from: {opacity: 0},
        to: {opacity: open ? 1 : 0},
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {React.cloneElement(children, {onClick})}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center content vertically
    justifyContent: 'center', // Center content horizontally
};


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const createData = (id,
                    createdAt,
                    updatedAt,
                    applicant_id_number,
                    first_name,
                    last_name,
                    phone,
                    faculty,
                    status,
) => {
    return {
        id,
        createdAt,
        updatedAt,
        applicant_id_number,
        first_name,
        last_name,
        phone,
        faculty,
        status,

    };
};

const columns = [
    {id: 'id', label: '№', minWidth: 50},
    {id: 'createdAt', label: 'Date', minWidth: 120},
    {id: 'updatedAt', label: 'Update', minWidth: 120},
    {id: 'applicant_id_number', label: 'MU_ID', minWidth: 80},
    {id: 'first_name', label: 'First Name', minWidth: 120},
    {id: 'last_name', label: 'Second Name', minWidth: 150},
    {id: 'phone', label: 'Phone', minWidth: 50, format: (value) => value.toLocaleString('en-US')},
    {id: 'faculty', label: 'Faculty', minWidth: 80, format: (value) => value.toLocaleString('en-US')},
    {id: 'status', label: 'Status', minWidth: 100, format: (value) => value.toFixed(2)},
    {id: 'view', label: 'View'},
];

export default function StickyHeadTable() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filterStatus, setFilterStatus] = React.useState('All');
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSpringModalOpen, setIsSpringModalOpen] = useState(false);
    const [filePath, setFilePath] = useState('');
    const [applicants, setApplicants] = useState([]);

    const [fileInfo, setFileInfo] = useState({
        filePath: "",
        isModalOpen: false
    });
    //------------------------- Applicant fields start---------------------------------------------------------//
    const [id, setApplicantId] = useState('');
    const [personalInfoDtoId, setPersonalInfoDtoId] = useState('');
    const [academicInfoDtoId, setAcademicInfoDtoId] = useState('');
    const [transferDetailsDtoId, setTransferDetailsDtoId] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [gender, setGender] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [passportDateOfExpire, setPassportDateOfExpire] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [emergencyContactNumber, setEmergencyContactNumber] = useState('');
    const [passportStatus, setPassportStatus] = useState('');
    const [attestatStatus, setAttestatStatus] = useState('');
    const [passportFilePath, setPassportFilePath] = useState('');
    const [attestatFilePath, setAttestatFilePath] = useState('');
    const [paymentReceiptFilePath, setPaymentReceiptFilePath] = useState('');
    const [paymentReceiptFileStatus, setPaymentReceiptFileStatus] = useState('');

    const [isTransferring, setIsTransferring] = useState('');
    const [currentUniversity, setCurrentUniversity] = useState("");
    const [toCourseYear, setToCourseYear] = useState("");
    const [transcriptStatus, setTranscriptStatus] = useState("");
    const [transcriptFilePath, setTranscriptFilePath] = useState("");

    const [hasEnglishProficiencyCertificate, setHasEnglishProficiencyCertificate] = useState([]);
    const [englishProficiencyLevel, setEnglishProficiencyLevel] = useState('');
    const [englishProficiencyType, setEnglishProficiencyType] = useState('');
    const [englishProficiencyStatus, setEnglishProficiencyStatus] = useState('');
    const [englishProficiencyFilePath, setEnglishProficiencyFilePath] = useState('');

    const [faculty, setFaculty] = useState('');
    const [programId, setProgramId] = useState('');
    const [courseType, setCourseType] = useState('');
    const [intake, setIntake] = useState('');

    const [employmentReferenceFilePath, setEmploymentReferenceFilePath] = useState('');
    const [employmentReferenceStatus, setEmploymentReferenceStatus] = useState('');
    const [workPlace, setWorkPlace] = useState('');

    const [hasSAT, setHasSAT] = useState('');
    const [satScore, setSatScore] = useState('');
    const [satfileStatus, setSatfileStatus] = useState('');
    const [satFilePath, setSatFilePath] = useState('');

    const [additionalFilePath, setAdditionalFilePath] = useState('');
    const [additionalFileStatus, setAdditionalFileStatus] = useState('');

    const [applicantType, setApplicantType] = useState('');
    const [applicantStatus, setApplicantStatus] = useState('');
    const [applicantContractFilePath, setApplicantContractFilePath] = useState('');
    const [registrationPlatform, setRegistrationPlatform] = useState('');
    const [applicantIDNumber, setApplicantIDNumber] = useState('');
    const [createdAt, setCreatedAt] = useState([]);
    const [updatedAt, setUpdatedAt] = useState([]);

    const [academicInfoDto, setAcademicInfoDto] = useState([]);
    const [personalInfoDto, setPersonalInfoDto] = useState([]);
    const [isAgreed, setIsAgreed] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [icon, setIcon] = useState('');
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [programs, setPrograms] = useState([]);
    const [employmentForm, setEmploymentForm] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [fetchedApplicant, setFetchedApplicant] = useState({});
    const [searchName, setSearchName] = useState('');
    const [open, setOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [additionalModalOpen, setAdditionalModalOpen] = useState(false);
    const [ascendingDate, setAscendingDate] = useState(true);
    const [ascendingUpdateDate, setAscendingUpdateDate] = useState(true);
    const [callerResponse, setCallerResponse] = useState('');
    const [comment, setComment] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [rejectReason, setRejectReason] = useState('');
    const [status, setStatus] = useState("");
    const [emailMessage, setEmailMessage] = useState('');
    const [message, setMessage] = useState('');
    const [fileUploadContract, setFileUploadContract] = useState(null);
    const [contractSignStatus, setContractSignStatus] = useState('');
    const [contractViewDate, setContractViewDate] = useState('');
    const [contractSignDate, setContractSignDate] = useState('');

    //------------------------- Applicant fields end---------------------------------------------------------//

    const rows = applicants.map((item) =>
        createData(
            item.id,
            item.createdAt,
            item.updatedAt,
            item.applicantIDNumber,
            item.personalInfoDto?.firstName || item.firstName,
            item.personalInfoDto?.lastName || item.lastName,
            item.personalInfoDto?.phone || item.phone,
            item.academicInfoDto?.programDto ? item.academicInfoDto.programDto.code : item.facultyCode ? item.facultyCode : 'N/A',
            item.applicationStatus
        )
    );

    const fetchApplicants = async () => {
        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants?page=${page}&size=${rowsPerPage}&sort=createdAt,asc`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error(response.status);
            }
            const data = await response.json();

            if (data && data.data) {
                setApplicants(data.data.content.sort((a, b) => {
                    const dateA = new Date(a.createdAt);
                    const dateB = new Date(b.createdAt);

                    return ascendingDate
                        ? dateA - dateB
                        : dateB - dateA
                }))
                setTotalElements(data.data.totalElements);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    };

    const searchApplicants = async () => {
        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/search?query=${searchName.toLowerCase()}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error(response.status);
            }
            const data = await response.json();

            if (data && data.data) {
                setApplicants(data.data)
                setTotalElements(data.data.length);
            }
        } catch (error) {
            console.log(`Error: ${error}`);
        }
    }

    const sortApplicantsByUpdatedAt = () => {
        setApplicants([...applicants].sort((a, b) => {
            const dateA = new Date(a.updatedAt);
            const dateB = new Date(b.updatedAt);
            return ascendingUpdateDate ? dateA - dateB : dateB - dateA; // Ascending order based on updatedAt, modify as needed
        }));
    };

    useEffect(() => {
        sortApplicantsByUpdatedAt();
    }, [ascendingUpdateDate]);

    const fetchPrograms = async () => {
        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/programs`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(response.status);
            }

            const data = await response.json();

            if (data && data.data) {
                setPrograms(data.data);
            }
        } catch (error) {
            console.log('Error:', error);
        }
    };

    useEffect(() => {
        fetchApplicants();
        fetchPrograms();
    }, [page, rowsPerPage, ascendingDate]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchName.trim() === '') {
                fetchApplicants();
                fetchPrograms();
            } else {
                searchApplicants()
            }
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [searchName])

    const clearUserData = () => {
        setPersonalInfoDtoId(null);
        setPersonalInfoDto(null);
        setFirstName('');
        setLastName('');
        setMiddleName('');
        setGender('');
        setCity('');
        setAddress('');
        setPhoneNumber('');
        setEmail('');
        setPassportNumber('');
        setEmergencyContactNumber('');
        setPassportStatus('');
        setAttestatStatus('');
        setDateOfBirth('');
        setPassportDateOfExpire('');
        setPassportFilePath('');
        setAttestatFilePath('');
        setPaymentReceiptFilePath('');

        setAcademicInfoDtoId(null);
        setAcademicInfoDto(null);
        setFaculty('');
        setProgramId('');
        setIntake('');
        setHasEnglishProficiencyCertificate(false);
        setEnglishProficiencyLevel('');
        setEnglishProficiencyType('');
        setEnglishProficiencyStatus('');
        setCourseType('');
        setEnglishProficiencyFilePath(false);
        setWorkPlace('');
        setEmploymentReferenceStatus('');
        setEmploymentReferenceFilePath(false);

        setIsTransferring(false);
        setTransferDetailsDtoId(null);
        setCurrentUniversity('');
        setToCourseYear('');
        setTranscriptStatus('');
        setTranscriptFilePath('');

        setHasSAT(false);
        setSatScore('');
        setSatFilePath('');
        setSatfileStatus('');
        setAdditionalFilePath('');
        setAdditionalFileStatus('');
        setApplicantStatus('');
        setCreatedAt('');
        setUpdatedAt('');
        setCallerResponse('');
        setComment('');
        setPaymentAmount('');
        setRejectReason('');
        setContractSignStatus('');
        setContractViewDate('');
        setContractSignDate('');
        setPaymentReceiptFileStatus('');
    }
    const fetchApplicantById = async (id) => {
        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/${id}`;

        try {
            clearUserData();
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error(response.status);
            }
            const data = await response.json();

            if (data && data.data && data.data.personalInfoDto) {
                const fetchedPersonalDetails = data.data.personalInfoDto;
                setPersonalInfoDtoId(fetchedPersonalDetails.id);
                setPersonalInfoDto(data.data.personalInfoDto);
                setFirstName(fetchedPersonalDetails.firstName);
                setLastName(fetchedPersonalDetails.lastName);
                setMiddleName(fetchedPersonalDetails.middleName);
                setGender(fetchedPersonalDetails.gender);
                setCity(fetchedPersonalDetails.city);
                setAddress(fetchedPersonalDetails.address);
                setPhoneNumber(fetchedPersonalDetails.phone);
                setEmail(fetchedPersonalDetails.email);
                setPassportNumber(fetchedPersonalDetails.passportNumber);
                setEmergencyContactNumber(fetchedPersonalDetails.emergencyContactNumber);
                setPassportStatus(fetchedPersonalDetails.passportStatus);
                setAttestatStatus(fetchedPersonalDetails.attestatStatus);
                setDateOfBirth(fetchedPersonalDetails.dateOfBirth);
                setPassportDateOfExpire(fetchedPersonalDetails.passportDateOfExpire);
                setPassportFilePath(fetchedPersonalDetails.passportFilePath);
                setAttestatFilePath(fetchedPersonalDetails.attestatFilePath);
            }

            if (data && data.data && data.data.academicInfoDto) {
                setComment(data.data.callComment);
                setPaymentAmount(data.data.paymentAmount);
                setCallerResponse(data.data.callerResponse);
                setRejectReason(data.data.rejectReason);
                setApplicantStatus(data.data.applicationStatus);
                setContractSignStatus(data.data.contractSignStatus);
                setContractViewDate(data.data.contractViewDate);
                setContractSignDate(data.data.contractSignDate);
                setPaymentReceiptFilePath(data.data.paymentReceiptFilePath);
                setPaymentReceiptFileStatus(data.data.paymentReceiptFileStatus);
                const fetchedAcademicInfoDto = data.data.academicInfoDto;
                const transferDetailsDto = data.data.academicInfoDto.transferDetailsDto;
                setAcademicInfoDtoId(fetchedAcademicInfoDto.id);
                setAcademicInfoDto(data.data.academicInfoDto);
                setFaculty(fetchedAcademicInfoDto.programDto.id);
                setProgramId(fetchedAcademicInfoDto.programDto.id);
                setIntake(fetchedAcademicInfoDto.intake);
                setHasEnglishProficiencyCertificate(fetchedAcademicInfoDto.hasEnglishProficiencyCertificate)
                setEnglishProficiencyLevel(fetchedAcademicInfoDto.englishProficiencyLevel);
                setEnglishProficiencyType(fetchedAcademicInfoDto.englishProficiencyType);
                setEnglishProficiencyStatus(fetchedAcademicInfoDto.englishProficiencyStatus);
                setCourseType(fetchedAcademicInfoDto.studyType);
                setEnglishProficiencyFilePath(fetchedAcademicInfoDto.englishProficiencyFilePath);
                // setEmploymentForm(true);
                setWorkPlace(fetchedAcademicInfoDto.workPlace);
                setEmploymentReferenceStatus(fetchedAcademicInfoDto.employmentReferenceStatus);
                setEmploymentReferenceFilePath(fetchedAcademicInfoDto.employmentReferenceFilePath);

                if (fetchedAcademicInfoDto.transferDetailsDto === null) {
                    setIsTransferring(false)
                } else {
                    setIsTransferring(true);
                    setTransferDetailsDtoId(fetchedAcademicInfoDto.transferDetailsDto.id);
                    setCurrentUniversity(fetchedAcademicInfoDto.transferDetailsDto.currentUniversity);
                    setToCourseYear(fetchedAcademicInfoDto.transferDetailsDto.toCourseYear);
                    setTranscriptStatus(fetchedAcademicInfoDto.transferDetailsDto.transcriptStatus);
                    setTranscriptFilePath(fetchedAcademicInfoDto.transferDetailsDto.transcriptFilePath);
                }
                setHasSAT(fetchedAcademicInfoDto.hasSAT);
                setSatScore(fetchedAcademicInfoDto.satScore);
                setSatFilePath(fetchedAcademicInfoDto.satfilePath);
                setSatfileStatus(fetchedAcademicInfoDto.satfileStatus);
                setAdditionalFilePath(fetchedAcademicInfoDto.additionalFilePath);
                setAdditionalFileStatus(fetchedAcademicInfoDto.additionalFileStatus);
                setApplicantContractFilePath(data.data.applicantContractFilePath);
            }

        } catch (error) {
            console.error(`Error: ${error}`);
        }

    };

    const downloadXLSX = async () => {
        const token = localStorage.getItem('userToken');
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/files/csv`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'file.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Failed to download file:', response.statusText);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const updateApplicantInfo = async () => {
        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants`;
        const formData = new FormData();
        setLoading(true);
        let personalInfoDto = {
            id: personalInfoDtoId,
            firstName,
            lastName,
            middleName,
            gender,
            dateOfBirth,
            city,
            address,
            passportNumber,
            passportDateOfExpire,
            phone,
            email,
            emergencyContactNumber,
            passportStatus,
            attestatStatus
        };

        let transferDetailsDto = null;
        if (isTransferring) {
            transferDetailsDto = {
                id: transferDetailsDtoId,
                currentUniversity: currentUniversity,
                toCourseYear: toCourseYear,
                transcriptStatus: transcriptStatus
            }
        };

        let academicInfoDto = {
            id: academicInfoDtoId,
            transferDetailsDto: transferDetailsDto,
            programDto: {id: faculty},
            intake: intake,
            studyType: courseType,
            workPlace: workPlace,
            employmentReferenceStatus: employmentReferenceStatus,
            hasEnglishProficiencyCertificate: hasEnglishProficiencyCertificate,
            englishProficiencyLevel: englishProficiencyLevel,
            englishProficiencyType: englishProficiencyType,
            englishProficiencyStatus: englishProficiencyStatus,
            hasSAT: hasSAT,
            satScore: satScore,
            satfileStatus: satfileStatus,
            additionalFileStatus: additionalFileStatus
        };

        let dataToSend = {
            id: id,
            personalInfoDto,
            academicInfoDto,
            applicantType: applicantType,
            applicationStatus: applicantStatus,
            callComment: comment,
            callerResponse: callerResponse,
            paymentAmount: paymentAmount,
            rejectReason: rejectReason,
            createdAt: createdAt,
            updatedAt: updatedAt,
            contractSignStatus: contractSignStatus,
            paymentReceiptFileStatus: paymentReceiptFileStatus
        };

        formData.append('applicantDto', JSON.stringify(dataToSend));
        handleSnackbarOpen();
        try {
            const response = await fetch(url, {
                method: 'PATCH',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                // Handle errors based on status code
                const errorData = await response.json();
                if (response.status === 400) {
                    console.error('Bad request:', errorData);
                    setIsSpringModalOpen(true);
                    setTitle('Invalid input');
                    setDescription('Please check for errors in fields. Ensure that you\'ve entered valid info. Check for any missing fields and try again')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                } else if (response.status === 500) {
                    console.error('Internal server error:', errorData);
                    setIsSpringModalOpen(true);
                    setTitle('Something Went Wrong');
                    setDescription('We apologize for the inconvenience. We\'re working to resolve the issue. Please try submitting your information again in a few minutes. If the issue persists, contact us for assistance.')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                } else if (response.status === 403) {
                    console.error('Forbidden', errorData);
                    setIsSpringModalOpen(true);
                    setTitle('Access Denied');
                    setDescription('You don\'t have permission to perform this operation')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                } else {
                    console.error('Unexpected error:', errorData);
                    setIsSpringModalOpen(true);
                    setTitle('Something Went Wrong');
                    setDescription('We apologize for the inconvenience. We\'re working to resolve the issue. Please try submitting your information again in a few minutes. If the issue persists, contact us for assistance.')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                }
                throw new Error('API request failed');
            }
            setLoading(false);
            return response;
        } catch (error) {
            console.log(`Error: ${error}`);
        } finally {
            setLoading(false);
        }
    };

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChangeFilterStatus = (status) => {
        setFilterStatus(status);
        handleCloseMenu();
    };

    const handleViewDetails = async (row) => {
        if (row && row.id) {
            setSelectedRow(row);
            setApplicantId(row.id);
            await fetchApplicantById(row.id);
            getUserEmailStatus(row.id);
        } else {
            console.error('Applicant ID not found in selected row');
        }
    };

    const handleAscendingDate = () => {
        setAscendingDate(!ascendingDate)
    }

    const handleAscendingUpdateDate = () => {
        setAscendingUpdateDate(!ascendingUpdateDate);
    }

    const isMenuOpen = Boolean(anchorEl);

    const onViewFileClick = (filePath) => {
        setFileInfo({
            filePath: filePath,
            isModalOpen: true
        });
    }; // Empty dependency array ensures it only runs once

    const closeModal = () => {
        setFileInfo(prevState => ({
            ...prevState,
            isModalOpen: false
        }));
    };

    const handleModalClose = () => {
        setIsSpringModalOpen(false);
    };
    const saveApplicantChange = () => {
        setIsConfirmationModalOpen(true);
    };

    const handleConfirmModal = async () => {
        setLoading(true); // Start loading before making the API request
        try {
            const apiResponse = await updateApplicantInfo(); // Assume this function updates the applicant's info and returns a response
            if (apiResponse && apiResponse.ok) {
                setIsSpringModalOpen(true); // This function opens another modal indicating success
                setTitle('Save Successful!'); // Sets the title of the success modal
                setDescription('The applicant changes have been saved successfully.'); // Sets its description
                setIcon(<CheckCircleIcon color="success"/>); // Sets the icon for the modal
            }
        } finally {
            setLoading(false); // Stop loading after operation is completed
            handleConfirmationModalClose(); // Close the confirmation modal
        }
    }

    const handleCancelModal = () => {
        setIsConfirmationModalOpen(false);
    }

    const handleConfirmationModalClose = () => {
        setIsConfirmationModalOpen(false);
        fetchApplicants()
    };

    const getUserEmailStatus = async () => {
        const token = localStorage.getItem('userToken');
        await fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/email/status/${email}`,{
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => response.json())
            .then(data => {
                setStatus(data.data);
            })
            .catch(error => {
                console.error('Error fetching status:', error);
            });
    };

    const {getRootProps: getRootProps1, getInputProps: getInputProps1} = useDropzone({
        accept: {
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        },
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const fileType = file.type
            const validTypes = [
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

            ];
            if (validTypes.includes(fileType)) {
                setFileUploadContract(file);
            } else {
                setIsModalOpen(true);
                setTitle('Invalid file type');
                setDescription('File was rejected. Invalid file format. Only DOC, DOCX file types are accepted. Please try again');
                setIcon(<WarningIcon sx={{fontSize: 50, color: 'red'}}/>)
            }
        },
    });

    const handleOpenDialog = async () => {
        const token = localStorage.getItem('userToken');
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/acceptance/message/${email}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (response.ok && data.success) {
                setMessage(data.data);
                setErrorMessage('');
            } else {
                setErrorMessage('Failed to retrieve acceptance message. Please try again.');
                setMessage('');
            }
            setOpen(true);
        } catch (error) {
            console.error('Error fetching message:', error);
            setErrorMessage('Failed to retrieve acceptance message. Please try again.');
            setMessage('');
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setMessage('');
        setErrorMessage('');
    };

    const handleEmailNext = () => {
        setAdditionalModalOpen(true);
        setOpen(false);
    }

    const handleAdditionalModalClose = () => {
        setAdditionalModalOpen(false);
        setMessage('');
    };

    const handleConfirm = async () => {
        setAdditionalModalOpen(false);
        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/confirm/applicant`;

        const formData = new FormData();
        formData.append('email', email);
        formData.append('messageBody', message);
        if (fileUploadContract) {
            formData.append('updatedContract', fileUploadContract);
        }
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!res.ok) {
                setIsModalOpen(true);
                setTitle('Something went wrong');
                setDescription('Network response was not ok. Try again later');
                setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                throw new Error('Network response was not ok');

            }

            const data = await res.json();
            setIsModalOpen(true);
            setTitle('Send succesfully');
            setDescription('');
            setIcon(<CheckCircleIcon sx={{fontSize: 50}} color="success"/>)

            console.log(data);
        } catch (error) {
            console.error('Error submitting the form', error);
            setIsModalOpen(true);
            setTitle('Error');
            setDescription('Error submitting the form');
            setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
        }
    };

    const handleChange = (event) => {
        setMessage(event.target.innerHTML, message)
    };

    const statusColor = {
        SUBMITTED: '#fdf501',
        APPROVED: '#57bb15',
        REJECTED: '#f44336',
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {year: 'numeric', month: 'long', day: 'numeric'};
        return date.toLocaleDateString('en-US', options);
    };

    const CustomMenuItem = styled(MenuItem)(({theme}) => ({
        [theme.breakpoints.only('xs')]: {
            whiteSpace: 'normal',
            minHeight: 48,
            lineHeight: '1.4em',
        },
    }));

    // API call to download student contract
    const handleDownload = async () => {
        console.log("Download working");
        const token = localStorage.getItem('userToken');
        const cacheBuster = new Date().getTime(); // This creates a unique identifier based on the current time
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/files/applicant/contract/download?login=${email}&cb=${cacheBuster}`;


        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();

            // Extract filename from the Content-Disposition header
            const contentDisposition = response.headers.get('Content-Disposition');
            console.log('Content-Disposition Header:', contentDisposition);
            let filename = 'applicant_contract.docx'; // default filename
            if (contentDisposition) {
                const match = contentDisposition.match(/filename="(.+)"/);
                if (match && match[1]) {
                    filename = match[1];
                }
            }
            console.log('Filename:', filename);
            // Create a URL for the blob object
            const blobUrl = window.URL.createObjectURL(blob); // Changed to blobUrl
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', filename); // Use the extracted filename
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            // Revoke the blob URL to free up memory
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading the file', error);
        }
    };

        return (
            <>
                <div className="header hedaer-before">
                    <div className="statistics-container">
                        <a className="statistics-link" href="/statistics" target="_blank">Statistics</a>
                    </div>
                </div>
                <div className="admin-table">
                    <div className="admin-table__left">
                        <div style={{display: "flex"}}>
                            <Input
                                className='mb-2'
                                startDecorator={<Search fontSize='lg'/>}
                                type="text"
                                placeholder="Search"
                                value={searchName}
                                style={{width: '600px'}}
                                onChange={e => setSearchName(e.target.value)}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={downloadXLSX}
                                className="mb-2"
                                style={{marginLeft: "15px"}}
                            >
                                Download File
                                <DownloadIcon style={{marginLeft: "5px"}}/>
                            </Button>
                        </div>

                        <Paper sx={{width: '100%', overflow: 'hidden'}}>
                            <TableContainer sx={{maxHeight: '700px', overflow: 'scroll'}}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{minWidth: column.minWidth}}
                                                >
                                                    {column.id === 'status' ? (
                                                        <>
                                                        <span
                                                            onClick={handleOpenMenu}
                                                            style={{cursor: 'pointer'}}
                                                        >
                                                            {column.label}
                                                            {isMenuOpen ? (
                                                                <KeyboardArrowUpIcon fontSize="small"/>
                                                            ) : (
                                                                <KeyboardArrowDownIcon fontSize="small"/>
                                                            )}
                                                        </span>
                                                            <Menu
                                                                anchorEl={anchorEl}
                                                                open={isMenuOpen}
                                                                onClose={handleCloseMenu}
                                                            >
                                                                <MenuItem
                                                                    onClick={() => handleChangeFilterStatus('All')}>
                                                                    All
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => handleChangeFilterStatus('SUBMITTED')}>
                                                                    Submitted
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => handleChangeFilterStatus('APPROVED')}>
                                                                    Approved
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => handleChangeFilterStatus('PENDING')}>
                                                                    Pending
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => handleChangeFilterStatus('REJECTED')}>
                                                                    Rejected
                                                                </MenuItem>
                                                            </Menu>
                                                        </>
                                                    ) : (
                                                        column.id === 'createdAt' ? (
                                                            <>
                                                                <span
                                                                    onClick={handleAscendingDate}
                                                                    style={{cursor: 'pointer'}}
                                                                >
                                                                    {column.label}
                                                                    {ascendingDate ? (
                                                                        <KeyboardArrowUpIcon fontSize="small"/>

                                                                    ) : (
                                                                        <KeyboardArrowDownIcon fontSize="small"/>
                                                                    )}
                                                                </span>
                                                            </>
                                                        ) : (column.id === 'updatedAt' ? (
                                                            <>
                                                                <span
                                                                    onClick={handleAscendingUpdateDate}
                                                                    style={{cursor: 'pointer'}}
                                                                >
                                                                    {column.label}
                                                                    {ascendingUpdateDate ? (
                                                                        <KeyboardArrowUpIcon fontSize="small"/>

                                                                    ) : (
                                                                        <KeyboardArrowDownIcon fontSize="small"/>
                                                                    )}
                                                                </span>
                                                            </>
                                                        ) : (column.label)))
                                                    }
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows
                                            .filter((row) => filterStatus === 'All' || row.status === filterStatus)
                                            .map((row, idx) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}>
                                                                    {value === "PENDING" ?
                                                                        <Typography style={{
                                                                            padding: "2px 5px",
                                                                            background: "yellow",
                                                                            borderRadius: "8px",
                                                                            textAlign: "center"
                                                                        }}>
                                                                            {value}
                                                                        </Typography> : (value === "REJECTED" ?
                                                                            <Typography style={{
                                                                                padding: "2px 5px",
                                                                                background: "#f31515",
                                                                                borderRadius: "8px",
                                                                                textAlign: "center"
                                                                            }}>
                                                                                {value}
                                                                            </Typography> : (value === "APPROVED" ?
                                                                                <Typography style={{
                                                                                    padding: "2px 5px",
                                                                                    background: "#17FA1F",
                                                                                    borderRadius: "8px",
                                                                                    textAlign: "center"
                                                                                }}>
                                                                                    {value}
                                                                                </Typography> : (value === "SUBMITTED" ?
                                                                                    <Typography style={{
                                                                                        padding: "2px 5px",
                                                                                        borderRadius: "8px",
                                                                                        textAlign: "center",
                                                                                        border: "1px solid #32383e"
                                                                                    }}>
                                                                                        {value}
                                                                                    </Typography> : (column.id === 'view' ? (
                                                                                        <Link to="#"
                                                                                              onClick={() => handleViewDetails(row)}>
                                                                                            View
                                                                                        </Link>
                                                                                    ) : (
                                                                                        column.id === 'createdAt' || column.id === 'updatedAt' ? (
                                                                                            new Date(value).toLocaleDateString('en-UZ', {
                                                                                                day: 'numeric',
                                                                                                month: 'long',
                                                                                                year: 'numeric'
                                                                                            })
                                                                                        ) : (
                                                                                            column.id === 'id' ? (
                                                                                                idx + 1 + page * rowsPerPage
                                                                                            ) : (
                                                                                                column.format && typeof value === 'number'
                                                                                                    ? column.format(value)
                                                                                                    : value
                                                                                            )
                                                                                        )
                                                                                    )))))}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 50, 100, 250, 1000]}
                                component="div"
                                count={totalElements}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                    {/*----------------------User info card------------------------------------------------------------------------------------------------------------------------*/}
                    <div className="admin-table__right">
                        {selectedRow && (
                            <Card style={{border: "1px solid black"}}>
                                <CardContent sx={{maxWidth: 600, margin: " 0 10px 10px"}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            <Typography variant="h4" component="h1">
                                                {selectedRow.first_name}
                                                <br/>
                                                {selectedRow.last_name}
                                            </Typography>
                                            <Typography variant="h5" component="h2">
                                                <strong>Applicant ID:</strong> {selectedRow.applicant_id_number}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                id="applicantStatus"
                                                label="Applicant Status"
                                                variant="outlined"
                                                value={applicantStatus}
                                                select
                                                onChange={e => {
                                                    setApplicantStatus(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            >
                                                <MenuItem value="SUBMITTED" sx={{color: "blue"}}
                                                          disabled>SUBMITTED</MenuItem>
                                                <MenuItem value="PENDING" sx={{color: "orange"}}>PENDING</MenuItem>
                                                <MenuItem value="APPROVED" sx={{color: "green"}}>APPROVED</MenuItem>
                                                <MenuItem value="REJECTED" sx={{color: "red"}}>REJECTED</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider style={{border: "1px solid gray"}}/>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" component="h3" style={{marginBottom: 10}}>
                                                Registration Details
                                            </Typography>
                                            <Typography variant="body1" style={{marginBottom: 5}}>
                                                <strong>Registration Date:</strong> {formatDate(selectedRow.createdAt)}
                                            </Typography>
                                            <Typography variant="body1" style={{marginBottom: 5}}>
                                                <strong>Last Update:</strong> {formatDate(selectedRow.updatedAt)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider style={{border: "1px solid gray"}}/>
                                        </Grid>
                                        {(applicantStatus === 'APPROVED' && applicantContractFilePath !== null) ?
                                            <>
                                                <Grid item xs={12}>
                                                    <div className="d-flex">
                                                        <div>
                                                            <Typography variant="h6" component="h3"
                                                                        style={{marginBottom: 10}}>
                                                                Student Contract
                                                            </Typography>
                                                            <div style={{display: "flex"}}>
                                                                <div>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={handleDownload}
                                                                        sx={{
                                                                            '&:hover': {
                                                                                color: 'white'
                                                                            }
                                                                        }}
                                                                    >
                                                                        Download
                                                                        <DownloadIcon style={{marginLeft: "5px"}}/>
                                                                    </Button>
                                                                </div>
                                                                <div style={{marginLeft: "10px"}}>
                                                                    <Button
                                                                        color="primary"
                                                                        variant="contained"
                                                                        onClick={handleOpenDialog}
                                                                        disabled={loading}
                                                                    >
                                                                        Send Mail
                                                                        <MailIcon style={{marginLeft: "5px"}}/>
                                                                    </Button>
                                                                    <Dialog
                                                                        PaperProps={{
                                                                            style: {
                                                                                width: '800px',
                                                                                maxWidth: '800px'
                                                                            }
                                                                        }}
                                                                        open={open}
                                                                        onClose={handleClose}
                                                                        aria-labelledby="message-dialog-title"
                                                                        aria-describedby="message-dialog-description"
                                                                    >
                                                                        <DialogTitle style={{textAlign: "center"}}
                                                                                     id="message-dialog-title">
                                                                            {"Message"}
                                                                        </DialogTitle>
                                                                        <DialogContent>
                                                                            <DialogContentText
                                                                                id="message-dialog-description">
                                                                                {message ? (
                                                                                    <div
                                                                                        contentEditable='true'
                                                                                        style={{
                                                                                            padding: '10px',
                                                                                            color: "#000"
                                                                                        }}
                                                                                        onBlur={handleChange}
                                                                                        dangerouslySetInnerHTML={{__html: message}}/>
                                                                                ) : (
                                                                                    <p style={{
                                                                                        color: 'red',
                                                                                        fontSize: "20px"
                                                                                    }}>{errorMessage}</p>
                                                                                )}
                                                                            </DialogContentText>
                                                                            <hr/>
                                                                            <Grid item style={{
                                                                                margin: '5px auto',
                                                                                textAlign: 'center'
                                                                            }} className="GridItem">
                                                                                {message &&
                                                                                    <div className="inputWithLabel"
                                                                                         style={{width: '100%'}}>
                                                                                        <label
                                                                                            style={{margin: '5px auto'}}
                                                                                            htmlFor="images">Contract to
                                                                                            Apllicant (Optional)</label>
                                                                                        <div {...getRootProps1()}
                                                                                             className="dropzone">
                                                                                            <input {...getInputProps1()}/>
                                                                                            {fileUploadContract ? (
                                                                                                <div>
                                                                                                    {fileUploadContract.type.startsWith('image/') ? (
                                                                                                        <img
                                                                                                            src={URL.createObjectURL(fileUploadContract)}
                                                                                                            alt="Uploaded"
                                                                                                            className="uploadedImage"
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <div
                                                                                                            className="fileInfo">
                                                                                                            <p>{fileUploadContract.name}</p>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    <button
                                                                                                        className="deleteButton"
                                                                                                        onClick={() => setFileUploadContract(null)}>
                                                                                                        <DeleteIcon/>
                                                                                                    </button>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <p>Drag & drop or click
                                                                                                    to
                                                                                                    select a file</p>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </Grid>
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button variant="contained"
                                                                                    onClick={handleClose}
                                                                                    color="primary">
                                                                                Close
                                                                            </Button>
                                                                            <Button variant="contained"
                                                                                    disabled={!message}
                                                                                    onClick={handleEmailNext}
                                                                                    color="primary">
                                                                                Send
                                                                            </Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                    <Dialog
                                                                        open={additionalModalOpen}
                                                                        onClose={handleAdditionalModalClose}
                                                                        aria-labelledby="message-dialog-title"
                                                                        aria-describedby="message-dialog-description"
                                                                    >
                                                                        <DialogContent>
                                                                            <DialogContentText
                                                                                id="message-dialog-description">
                                                                                Are you sure to send this email?
                                                                            </DialogContentText>
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button variant="contained"
                                                                                    onClick={handleAdditionalModalClose}
                                                                                    color="primary">
                                                                                Cancel
                                                                            </Button>
                                                                            <Button variant="contained"
                                                                                    onClick={handleConfirm}
                                                                                    color="primary">
                                                                                Yes, sure
                                                                            </Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                    <SpringModal
                                                                        open={isModalOpen}
                                                                        handleClose={() => setIsModalOpen(false)}
                                                                        title={title}
                                                                        description={description}
                                                                        icon={icon}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Grid item xs={12}>
                                                        <Divider style={{
                                                            border: "1px solid gray",
                                                            marginTop: 20,
                                                            marginBottom: 10
                                                        }}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" component="h3"
                                                                    style={{marginBottom: 10}}>
                                                            Contract Information
                                                        </Typography>
                                                    </Grid>
                                                    <Grid style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        margin: "10px 0"
                                                    }} item xs={12}>
                                                        <Typography variant="h6">
                                                            Contract Status: <span
                                                            style={{fontSize: "15px"}}>{contractSignStatus}</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        margin: "10px 0"
                                                    }} item xs={12}>
                                                        <Typography variant="h6">
                                                            Contract View Date: <span
                                                            style={{fontSize: "15px"}}>{formatDate(contractViewDate ? contractViewDate : "No Date")}</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        margin: "10px 0"
                                                    }} item xs={12}>
                                                        <Typography variant="h6">
                                                            Contract Sign Date: <span
                                                            style={{fontSize: "15px"}}>{formatDate(contractSignDate ? contractSignDate : "No Date")}</span>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Contract Receipt</TableCell>
                                                                            <TableCell>Contract Receipt
                                                                                Status</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <Button style={{
                                                                                    width: "110px",
                                                                                    padding: "5px"
                                                                                }} variant="outlined"
                                                                                        onClick={() => onViewFileClick(paymentReceiptFilePath)}>
                                                                                    Contract
                                                                                </Button>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Button
                                                                                    style={{
                                                                                        cursor: "pointer",
                                                                                        color: "black",
                                                                                        padding: "8px 15px",
                                                                                        pointerEvents: "none",
                                                                                    }}
                                                                                    sx={{backgroundColor: statusColor[paymentReceiptFileStatus]}}>
                                                                                    {paymentReceiptFileStatus}
                                                                                </Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            : null}
                                        <Grid item xs={12}>
                                            <Typography variant="h6" component="h3" style={{marginBottom: 10}}>
                                                Personal Information
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="firstName"
                                                label="Firstname"
                                                variant="outlined"
                                                value={firstName}
                                                fullWidth
                                                onChange={e => {
                                                    setFirstName(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="lastName"
                                                label="Lastname"
                                                variant="outlined"
                                                value={lastName}
                                                fullWidth
                                                onChange={e => {
                                                    setLastName(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#000',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="middleName"
                                                label="Middle Name"
                                                variant="outlined"
                                                fullWidth
                                                value={middleName}
                                                onChange={e => {
                                                    setMiddleName(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="gender"
                                                label="Gender"
                                                variant="outlined"
                                                fullWidth
                                                value={gender}
                                                select
                                                onChange={e => {
                                                    setGender(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <MenuItem value="MALE">Male</MenuItem>
                                                <MenuItem value="FEMALE">Female</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="dob"
                                                label="Date of Birth"
                                                variant="outlined"
                                                fullWidth
                                                value={dateOfBirth}
                                                onChange={e => {
                                                    setDateOfBirth(e.target.value)
                                                }}
                                                type={"date"}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                required
                                                id="city"
                                                label="City"
                                                select
                                                fullWidth
                                                variant="outlined"
                                                value={city}
                                                onChange={e => {
                                                    setCity(e.target.value);
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                <MenuItem value="Tashkent City">Tashkent City</MenuItem>
                                                <MenuItem value="Tashkent Region">Tashkent Region</MenuItem>
                                                <MenuItem value="Samarqand">Samarqand</MenuItem>
                                                <MenuItem value="Navoiy">Navoiy</MenuItem>
                                                <MenuItem value="Buxoro">Buxoro</MenuItem>
                                                <MenuItem value="Sirdaryo">Sirdaryo</MenuItem>
                                                <MenuItem value="Jizzax">Jizzax</MenuItem>
                                                <MenuItem value="Xorazm">Xorazm</MenuItem>
                                                <MenuItem value="Surxondaryo">Surxondaryo</MenuItem>
                                                <MenuItem value="Namangan">Namangan</MenuItem>
                                                <MenuItem value="Andijon">Andijon</MenuItem>
                                                <MenuItem value="Qashqadaryo">Qashqadaryo</MenuItem>
                                                <MenuItem value="Fargʻona">Fargʻona</MenuItem>
                                                <MenuItem value="Qoraqalpogʻiston">Qoraqalpogʻiston </MenuItem>
                                                <MenuItem value="Others">Others</MenuItem>
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                id="email"
                                                label="Email"
                                                variant="outlined"
                                                value={email}
                                                fullWidth
                                                onChange={e => {
                                                    setEmail(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                id="address"
                                                label="Address"
                                                variant="outlined"
                                                fullWidth
                                                value={address}
                                                onChange={e => {
                                                    setAddress(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                id="phone"
                                                label="Phone"
                                                variant="outlined"
                                                fullWidth
                                                value={phone}
                                                onChange={e => {
                                                    setPhoneNumber(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="emergencyContactNumber"
                                                label="Emergency Contact Number(Parents)"
                                                variant="outlined"
                                                value={emergencyContactNumber}
                                                fullWidth
                                                onChange={e => {
                                                    setEmergencyContactNumber(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                id="passportNumber"
                                                label="Passport Serial Number"
                                                variant="outlined"
                                                value={passportNumber}
                                                fullWidth
                                                onChange={e => {
                                                    setPassportNumber(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="passportDateOfExpire"
                                                label="Passport date of expiry"
                                                variant="outlined"
                                                type={"date"}
                                                value={passportDateOfExpire}
                                                fullWidth
                                                onChange={e => {
                                                    setPassportDateOfExpire(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider style={{border: "1px solid gray"}}/>
                                        </Grid>
                                        {/*------------------------ Academic Information Section ---------------------------------------------------*/}
                                        <Grid item xs={12}>
                                            <Typography variant="h6" component="h3" style={{marginBottom: 10}}>
                                                Academic Information
                                            </Typography>
                                        </Grid>

                                        {isTransferring ? (
                                            <>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="currentUniversity"
                                                        label="Current University"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={currentUniversity}
                                                        onChange={e => {
                                                            setCurrentUniversity(e.target.value)
                                                        }}
                                                        style={{
                                                            marginTop: '10px',
                                                            marginBottom: '10px',
                                                            display: 'block',
                                                            backgroundColor: '#fff',
                                                            borderColor: '#47b749',
                                                            borderRadius: '5px'
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: {
                                                                marginRight: '10px'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        id="toCourseYear"
                                                        label="Transfer Course Year"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={toCourseYear}
                                                        onChange={e => {
                                                            setToCourseYear(e.target.value)
                                                        }}
                                                        style={{
                                                            marginTop: '10px',
                                                            marginBottom: '10px',
                                                            display: 'block',
                                                            backgroundColor: '#fff',
                                                            borderColor: '#47b749',
                                                            borderRadius: '5px'
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: {
                                                                marginRight: '10px'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        ) : null}

                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                id="faculty"
                                                name="faculty"
                                                label="Bachelor degree program"
                                                fullWidth
                                                select
                                                variant="outlined"
                                                value={faculty}
                                                onChange={e => {
                                                    setFaculty(e.target.value);
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            >
                                                {courseType === "FULLTIME"
                                                    ?
                                                    programs.map((program, i) => (
                                                        <CustomMenuItem key={i} value={program.id}>{program.name}</CustomMenuItem>
                                                    ))
                                                    :
                                                    (courseType === "EVENING" && programs.some(program => program.id === 3))
                                                        ?
                                                        programs.filter(program => program.id === 3)
                                                            .map((program, i) => (
                                                                <CustomMenuItem key={i} value={program.id}>{program.name}</CustomMenuItem>
                                                            ))
                                                        :
                                                        programs.filter(program => [1, 5, 2].includes(program.id))
                                                            .map((program, i) => (
                                                                <CustomMenuItem key={i} value={program.id}>{program.name}</CustomMenuItem>
                                                            ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="studyType"
                                                label="Study type"
                                                variant="outlined"
                                                value={courseType}
                                                select
                                                fullWidth
                                                onChange={e => {
                                                    setCourseType(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            >
                                                <MenuItem value="CORRESPONDENCE"
                                                          onClick={() => setEmploymentForm(true)}>Part
                                                    Time (Заочный)
                                                </MenuItem>
                                                <MenuItem value="FULLTIME" onClick={() => setEmploymentForm(false)}>Full
                                                    Time (Очный)
                                                </MenuItem>
                                                <MenuItem value="EVENING" onClick={() => setEmploymentForm(false)}>
                                                    Evening
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="intake"
                                                label="Intake"
                                                variant="outlined"
                                                value={intake}
                                                fullWidth
                                                onChange={e => {
                                                    setIntake(e.target.value)
                                                }}
                                                style={{
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                    display: 'block',
                                                    backgroundColor: '#fff',
                                                    borderColor: '#47b749',
                                                    borderRadius: '5px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {
                                                        marginRight: '10px'
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        {courseType === 'CORRESPONDENCE' ? (
                                            <>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id="workPlace"
                                                        label="Work place"
                                                        variant="outlined"
                                                        value={workPlace}
                                                        onChange={e => {
                                                            setWorkPlace(e.target.value)
                                                        }}
                                                        style={{
                                                            marginTop: '10px',
                                                            marginBottom: '10px',
                                                            display: 'block',
                                                            backgroundColor: '#fff',
                                                            borderColor: '#47b749',
                                                            borderRadius: '5px'
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: {
                                                                marginRight: '10px'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        ) : null}
                                        {hasEnglishProficiencyCertificate ? (
                                            <>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id="englishProficiencyLevel"
                                                        name="englishProficiencyLevel"
                                                        label="English proficiency level"
                                                        select
                                                        variant="outlined"
                                                        value={englishProficiencyLevel}
                                                        fullWidth
                                                        onChange={e => {
                                                            setEnglishProficiencyLevel(e.target.value);
                                                        }}
                                                        style={{
                                                            marginTop: '10px',
                                                            marginBottom: '10px',
                                                            display: 'block',
                                                            backgroundColor: '#fff',
                                                            borderColor: '#47b749',
                                                            borderRadius: '5px'
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: {
                                                                marginRight: '10px'
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem value="A1">A1</MenuItem>
                                                        <MenuItem value="A2">A2</MenuItem>
                                                        <MenuItem value="B1">B1</MenuItem>
                                                        <MenuItem value="B2">B2</MenuItem>
                                                        <MenuItem value="C1">C1</MenuItem>
                                                        <MenuItem value="C2">C2</MenuItem>
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id="englishProficiencyType"
                                                        name="englishProficiencyType"
                                                        label="English proficiency type"
                                                        fullWidth
                                                        select
                                                        variant="outlined"
                                                        value={englishProficiencyType}
                                                        onChange={e => {
                                                            setEnglishProficiencyType(e.target.value);
                                                        }}
                                                        style={{
                                                            marginTop: '10px',
                                                            marginBottom: '10px',
                                                            display: 'block',
                                                            backgroundColor: '#fff',
                                                            borderColor: '#47b749',
                                                            borderRadius: '5px'
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: {
                                                                marginRight: '10px'
                                                            }
                                                        }}
                                                    >
                                                        <MenuItem value="IELTS">IELTS</MenuItem>
                                                        <MenuItem value="TOEFL">TOEFL</MenuItem>
                                                        <MenuItem value="CEFR">CEFR</MenuItem>
                                                        <MenuItem value="DUOLINGO">Duolingo</MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </>
                                        ) : (
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="englishProficiencyCertificate"
                                                    label="English proficiency Certificate"
                                                    variant="outlined"
                                                    value={"Not Available"}
                                                    style={{
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        display: 'block',
                                                        backgroundColor: '#fff',
                                                        borderColor: '#47b749',
                                                        borderRadius: '5px'
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: {
                                                            marginRight: '10px'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                        {hasSAT ? (
                                            <>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        id="satScore"
                                                        label="SAT Score"
                                                        variant="outlined"
                                                        value={satScore}
                                                        fullWidth
                                                        onChange={e => {
                                                            setSatScore(e.target.value)
                                                        }}
                                                        style={{
                                                            marginTop: '10px',
                                                            marginBottom: '10px',
                                                            display: 'block',
                                                            backgroundColor: '#fff',
                                                            borderColor: '#47b749',
                                                            borderRadius: '5px'
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                            style: {
                                                                marginRight: '10px'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            <Grid item xs={6}>
                                                <TextField
                                                    id="satCertificaet"
                                                    label="SAT Certificate"
                                                    variant="outlined"
                                                    value={"Not Available"}
                                                    style={{
                                                        marginTop: '10px',
                                                        marginBottom: '10px',
                                                        display: 'block',
                                                        backgroundColor: '#fff',
                                                        borderColor: '#47b749',
                                                        borderRadius: '5px'
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: {
                                                            marginRight: '10px'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        )}

                                        <Grid item xs={12}>
                                            <Divider style={{border: "1px solid gray"}}/>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant="h6" component="h3" style={{marginBottom: 10}}>
                                                Document Verification
                                            </Typography>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Document</TableCell>
                                                                <TableCell>Status</TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell>isAvailable</TableCell>
                                                                <TableCell/>
                                                                <TableCell/>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Button style={{width: "110px", padding: "5px"}}
                                                                            variant="outlined"
                                                                            onClick={() => onViewFileClick(passportFilePath)}>
                                                                        Passport
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "black",
                                                                            padding: "8px 15px",
                                                                            pointerEvents: "none",
                                                                        }}
                                                                        sx={{backgroundColor: statusColor[passportStatus]}}>
                                                                        {passportStatus}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Select
                                                                        value={passportStatus}
                                                                        size={"small"}
                                                                        onChange={(event) => setPassportStatus(event.target.value)}
                                                                    >
                                                                        <MenuItem value="APPROVED">APPROVED</MenuItem>
                                                                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                                                                    </Select>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {passportFilePath !== null ?
                                                                        'true' : 'false'}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Button style={{width: "110px", padding: "5px"}}
                                                                            variant="outlined"
                                                                            onClick={() => onViewFileClick(attestatFilePath)}>
                                                                        Attestat
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "black",
                                                                            padding: "8px 15px",
                                                                            pointerEvents: "none",
                                                                        }}
                                                                        sx={{backgroundColor: statusColor[attestatStatus]}}>
                                                                        {attestatStatus}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Select
                                                                        value={attestatStatus}
                                                                        size={"small"}
                                                                        onChange={(event) => setAttestatStatus(event.target.value)}
                                                                    >
                                                                        <MenuItem value="APPROVED">APPROVED</MenuItem>
                                                                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                                                                    </Select>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {passportFilePath !== null ?
                                                                        'true' : 'false'}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Button style={{width: "110px", padding: "5px"}}
                                                                            variant="outlined"
                                                                            onClick={() => onViewFileClick(englishProficiencyFilePath)}>
                                                                        Eng Cert
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "black",
                                                                            padding: "8px 15px",
                                                                            pointerEvents: "none",
                                                                        }}
                                                                        sx={{backgroundColor: statusColor[englishProficiencyStatus]}}>
                                                                        {englishProficiencyStatus}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Select
                                                                        value={englishProficiencyStatus}
                                                                        size={"small"}
                                                                        onChange={(event) => setEnglishProficiencyStatus(event.target.value)}
                                                                    >
                                                                        <MenuItem value="APPROVED">APPROVED</MenuItem>
                                                                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                                                                    </Select>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {englishProficiencyFilePath !== null ?
                                                                        'true' : 'false'}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Button style={{width: "110px", padding: "5px"}}
                                                                            variant="outlined"
                                                                            onClick={() => onViewFileClick(transcriptFilePath)}>
                                                                        Transcript
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "black",
                                                                            padding: "8px 15px",
                                                                            pointerEvents: "none",
                                                                        }}
                                                                        sx={{backgroundColor: statusColor[transcriptStatus]}}>
                                                                        {transcriptStatus}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Select
                                                                        value={transcriptStatus}
                                                                        size={"small"}
                                                                        onChange={(event) => setTranscriptStatus(event.target.value)}
                                                                    >
                                                                        <MenuItem value="APPROVED">APPROVED</MenuItem>
                                                                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                                                                    </Select>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {transcriptFilePath !== null ?
                                                                        'true' : 'false'}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Button style={{width: "110px", padding: "5px"}}
                                                                            variant="outlined"
                                                                            onClick={() => onViewFileClick(employmentReferenceFilePath)}>
                                                                        Emp Ref
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "black",
                                                                            padding: "8px 15px",
                                                                            pointerEvents: "none",
                                                                        }}
                                                                        sx={{backgroundColor: statusColor[employmentReferenceStatus]}}>
                                                                        {employmentReferenceStatus}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Select
                                                                        value={employmentReferenceStatus}
                                                                        size={"small"}
                                                                        onChange={(event) => setEmploymentReferenceStatus(event.target.value)}
                                                                    >
                                                                        <MenuItem value="APPROVED">APPROVED</MenuItem>
                                                                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                                                                    </Select>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {employmentReferenceStatus !== null ?
                                                                        'true' : 'false'}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Button style={{width: "110px", padding: "5px"}}
                                                                            variant="outlined"
                                                                            onClick={() => onViewFileClick(satFilePath)}>
                                                                        SAT
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "black",
                                                                            padding: "8px 15px",
                                                                            pointerEvents: "none",
                                                                        }}
                                                                        sx={{backgroundColor: statusColor[satfileStatus]}}>
                                                                        {satfileStatus}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Select
                                                                        value={satfileStatus}
                                                                        size={"small"}
                                                                        onChange={(event) => setSatfileStatus(event.target.value)}
                                                                    >
                                                                        <MenuItem value="APPROVED">APPROVED</MenuItem>
                                                                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                                                                    </Select>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {selectedRow.sat_file_path !== null ?
                                                                        'true' : 'false'}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Button style={{width: "110px", padding: "5px"}}
                                                                            variant="outlined"
                                                                            onClick={() => onViewFileClick(additionalFilePath)}>
                                                                        Extra Cert
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "black",
                                                                            padding: "8px 15px",
                                                                            pointerEvents: "none",
                                                                        }}
                                                                        sx={{backgroundColor: statusColor[additionalFileStatus]}}>
                                                                        {additionalFileStatus}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Select
                                                                        value={additionalFileStatus}
                                                                        size={"small"}
                                                                        onChange={(event) => setAdditionalFileStatus(event.target.value)}
                                                                    >
                                                                        <MenuItem value="APPROVED">APPROVED</MenuItem>
                                                                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                                                                    </Select>
                                                                </TableCell>
                                                                <TableCell>
                                                                    {additionalFilePath !== null ?
                                                                        'true' : 'false'}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider style={{border: "1px solid gray"}}/>
                                    </Grid>

                                    <Grid container>
                                        <Grid xs={12} my={3}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Call Response</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={callerResponse}
                                                    onChange={(e) => {
                                                        setCallerResponse(e.target.value);
                                                    }}
                                                    displayEmpty
                                                    label="Call Response"
                                                >
                                                    <MenuItem><em>Select Option</em></MenuItem>
                                                    <MenuItem value="He/She will think">He/She will think</MenuItem>
                                                    <MenuItem value="Internal exam">Internal exam</MenuItem>
                                                    <MenuItem value="Contract should be sent">Contract should be
                                                        sent</MenuItem>
                                                    <MenuItem value="Want to cancel">Want to cancel</MenuItem>
                                                    <MenuItem value="Payment on wait">Payment on wait</MenuItem>
                                                    <MenuItem value="Want to change faculty">Want to change
                                                        faculty</MenuItem>
                                                    <MenuItem value="Wrong number">Wrong number</MenuItem>
                                                    <MenuItem value="He wants to pass the state university exam">He
                                                        wants to pass the state university exam</MenuItem>
                                                    <MenuItem value="No answer">No answer</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid xs={12} my={4}>
                                            <InputLabel id="demo-simple-select-label">Additional Notes</InputLabel>
                                            <form onSubmit={(event) => {
                                                event.preventDefault()
                                            }}>
                                                <Textarea
                                                    placeholder="Type anything…"
                                                    variant='soft'
                                                    sx={{'border': '1px solid #1976d2', 'borderRadius': '10px'}}
                                                    minRows={4}
                                                    size='lg'
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}
                                                />
                                            </form>
                                        </Grid>

                                        <Grid xs={12} my={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Payment Amount</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={paymentAmount}
                                                    onChange={(e) => {
                                                        setPaymentAmount(e.target.value);
                                                    }}
                                                    displayEmpty
                                                    label="Payment Amount"
                                                >
                                                    <MenuItem><em>Select Option</em></MenuItem>
                                                    <MenuItem value="1 mln UZS">1 mln UZS</MenuItem>
                                                    <MenuItem value="50%">50%</MenuItem>
                                                    <MenuItem value="100%">100%</MenuItem>
                                                    <MenuItem value="partly">Partly</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid xs={12} my={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Reject Reason</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={rejectReason}
                                                    onChange={(e) => {
                                                        setRejectReason(e.target.value);
                                                    }}
                                                    displayEmpty
                                                    label="Reject Reason"
                                                >
                                                    <MenuItem><em>Select Option</em></MenuItem>
                                                    <MenuItem value="9-10 grade pupil">9-10 grade pupil</MenuItem>
                                                    <MenuItem value="He/she transferred to another university">He/she
                                                        transferred to another university</MenuItem>
                                                    <MenuItem value="Just registered">Just registered</MenuItem>
                                                    <MenuItem value="The contract fee is expensive">The contract fee is
                                                        expensive</MenuItem>
                                                    <MenuItem value="No dormitory">Because there is no
                                                        dormitory</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 3}}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            size="Normal"
                                            startIcon={<SaveIcon/>}
                                            onClick={saveApplicantChange}
                                            sx={{ml: 1}}
                                        >
                                            Save
                                        </Button>
                                    </Box>

                                    <FileModal
                                        isModalOpen={fileInfo.isModalOpen}
                                        closeModal={closeModal}
                                        filePath={fileInfo.filePath}
                                    />


                                </CardContent>
                                <Snackbar
                                    variant="solid"
                                    color="success"
                                    open={snackbarOpen}
                                    onClose={handleSnackbarClose}
                                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                    autoHideDuration={2000}
                                    startDecorator={<PlaylistAddCheckCircleRoundedIcon/>}
                                >
                                    Your data has been saved successfully.
                                </Snackbar>

                                <Backdrop
                                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                                    open={loading}
                                >
                                    <CircularProgress color="inherit"/>
                                </Backdrop>

                                <SpringModal
                                    open={isSpringModalOpen}
                                    handleClose={handleModalClose}
                                    title={title}
                                    description={description}
                                    icon={icon}
                                />
                            </Card>
                        )}
                    </div>
                </div>
                {!loading && (
                    <Modal
                        aria-labelledby="spring-modal-title"
                        aria-describedby="spring-modal-description"
                        open={isConfirmationModalOpen}
                        onClose={handleConfirmationModalClose}
                        closeAfterTransition // Close after the transition animation is complete
                        BackdropComponent={Backdrop} // Specify custom Backdrop component
                        BackdropProps={{
                            timeout: 500, // Set custom timeout for backdrop animation
                        }}
                    >
                        <Fade in={isConfirmationModalOpen}>
                            <Box sx={modalStyle}>
                                {/*<CheckCircleIcon sx={{ fontSize: 72, color: 'green' }} />*/}
                                <div>{<WarningIcon/>}</div>
                                <Typography id="spring-modal-title" variant="h5" component="h2" sx={{mt: 2}}>
                                    Confirm Save
                                </Typography>
                                <Typography id="spring-modal-description" sx={{mt: 2}}>
                                    Are you sure you want to save the changes you made to this applicant? This action cannot be undone.
                                </Typography>
                                <div style={{display: "flex", marginTop: "10px"}}>
                                    <Button onClick={handleCancelModal} variant="contained" color="error">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleConfirmModal} variant="contained" color="success" sx={{ml: 2}}>
                                        Confirm
                                    </Button>
                                </div>
                            </Box>
                        </Fade>
                    </Modal>
                )}
            </>
        );
    }