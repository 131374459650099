import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import PhoneInput from 'react-phone-number-input';
import {useDropzone} from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import 'react-phone-number-input/style.css';
import "../../assets/css/style.css";
import "react-datepicker/dist/react-datepicker.css";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Snackbar from "@mui/joy/Snackbar";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";
import {Link, useNavigate} from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CopyrightForm from "./CopyrightForm";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SpringModal from "../utils/SpringModal";
import WarningIcon from '@mui/icons-material/Warning';
import Modal from "@mui/material/Modal";

export default function PersonalDetailsForm(props) {
    const [id, setId] = useState('');
    const [applicantId, setApplicantId] = useState('');
    const [personalInfoId, setPersonalInfoId] = useState('');
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [gender, setGender] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [passportDateOfExpire, setPassportDateOfExpire] = useState('');
    const [phone, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [emergencyContactNumber, setEmergencyContactNumber] = useState('');
    const [passportStatus, setPassportStatus] = useState('');
    const [attestatStatus, setAttestatStatus] = useState('');
    const [uploadedPassportFile, setUploadedFile1] = useState(null);
    const [uploadedAttestatFile, setUploadedFile2] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const navigate = useNavigate();
    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const [dataSaved, setDataSaved] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [icon, setIcon] = useState(null);
    const [isFormChanged, setFormChange] = useState(false);
    const [status, setStatus] = useState('');
    const [open, setOpen] = React.useState(true);

    useEffect(() => {
        setAllFieldsFilled(
            firstName.trim() !== '' &&
            lastName.trim() !== '' &&
            middleName !== null &&
            gender !== null &&
            dateOfBirth !== null &&
            city !== null &&
            address !== '' &&
            passportNumber.trim() !== '' &&
            passportDateOfExpire !== null &&
            phone.trim() !== '' &&
            email.trim() !== '' &&
            emergencyContactNumber !== null &&
            validateFiles()
        );
    }, [firstName, lastName, middleName,gender, dateOfBirth, city, address, passportNumber, passportDateOfExpire, phone, email, emergencyContactNumber, uploadedPassportFile, uploadedAttestatFile]);

    const validateFiles = () => {
        if (passportStatus !== null && attestatStatus !== null) {
            return true;
        }
        if (passportStatus === null) {
            return uploadedPassportFile !== null
        }
        if (attestatStatus === null) {
            return uploadedAttestatFile !== null;
        }

    }

    useEffect(() => {
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/applicant`;
        const fetchUserData = async () => {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error(response.status);
                }
                const data = await response.json();
                if (data) {
                    console.log(data)
                    if (data) {
                        setStatus(data.data.applicationStatus)
                    }
                }
            } catch (error) {
                console.error(`Error: ${error}`);
            }
        }
        fetchUserData()
    }, [])


    useEffect(() => {
        const fetchPersonalInfo = async () => {
            const token = localStorage.getItem('userToken');
            if (token === null) {
                navigate("/login");
            }
            const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/personal-info`;
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
                if (!response.ok) {
                    throw new Error(response.status);
                }
                const data = await response.json();
                if (data && data.data) {
                    const fetchedPersonalDetails = data.data;
                    setId(fetchedPersonalDetails.id);
                    setPersonalInfoId(fetchedPersonalDetails.id);
                    setApplicantId(fetchedPersonalDetails.applicantId);
                    setFirstName(fetchedPersonalDetails.firstName);
                    setLastName(fetchedPersonalDetails.lastName);
                    setMiddleName(fetchedPersonalDetails.middleName);
                    setGender(fetchedPersonalDetails.gender);
                    setCity(fetchedPersonalDetails.city);
                    setAddress(fetchedPersonalDetails.address);
                    setPhoneNumber(fetchedPersonalDetails.phone);
                    setEmail(fetchedPersonalDetails.email);
                    setPassportNumber(fetchedPersonalDetails.passportNumber);
                    setEmergencyContactNumber(fetchedPersonalDetails.emergencyContactNumber);
                    setPassportStatus(fetchedPersonalDetails.passportStatus);
                    setAttestatStatus(fetchedPersonalDetails.attestatStatus);
                    setDateOfBirth(fetchedPersonalDetails.dateOfBirth);
                    setPassportDateOfExpire(fetchedPersonalDetails.passportDateOfExpire);


                    // Check if all fields are filled
                    let allFieldsFilledCheck =
                        fetchedPersonalDetails.id &&
                        fetchedPersonalDetails.applicantId &&
                        fetchedPersonalDetails.firstName &&
                        fetchedPersonalDetails.lastName &&
                        // fetchedPersonalDetails.middleName &&
                        fetchedPersonalDetails.gender &&
                        fetchedPersonalDetails.city &&
                        fetchedPersonalDetails.address &&
                        fetchedPersonalDetails.phone &&
                        fetchedPersonalDetails.email &&
                        fetchedPersonalDetails.passportNumber &&
                        fetchedPersonalDetails.emergencyContactNumber &&
                        fetchedPersonalDetails.passportStatus &&
                        fetchedPersonalDetails.attestatStatus &&
                        fetchedPersonalDetails.dateOfBirth &&
                        fetchedPersonalDetails.passportDateOfExpire;

                    setAllFieldsFilled(allFieldsFilledCheck);
                }

            } catch (error) {
                console.log(`Error: ${error}`);
            }
        };
        fetchPersonalInfo();
    }, []);

    const submitPersonalInfoV1 = async () => {
        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/personal-info`;

        setLoading(true);

        const formData = new FormData();
        formData.append('id', personalInfoId);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('middleName', middleName);
        formData.append('gender', gender);
        formData.append('dateOfBirth', dateOfBirth);
        formData.append('city', city);
        formData.append('address', address);
        formData.append('passportNumber', passportNumber);
        formData.append('phone', phone);
        formData.append('email', email);
        formData.append('emergencyContactNumber', emergencyContactNumber);
        formData.append('passportDateOfExpire', passportDateOfExpire);
        if (passportStatus !== null) formData.append('passportFileStatus', passportStatus);
        if (attestatStatus !== null) formData.append('attestatFileStatus', attestatStatus);
        if (uploadedPassportFile) formData.append('passportFile', uploadedPassportFile);
        if (uploadedAttestatFile) formData.append('attestatFile', uploadedAttestatFile);


        handleSnackbarOpen();

        try {
            const response = await fetch(url, {
                method: 'PATCH',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                // Handle errors based on status code
                const errorData = await response.json(); // Parse error response
                if (response.status === 400) {
                    // Display modal for 400 (Bad Request) errors
                    console.error('Bad request:', errorData);
                    // Call your modal opening function here
                    setIsModalOpen(true);// Replace with your modal logic
                    setTitle('Invalid input');
                    setDescription('Please check for errors in fields. Ensure that you\'ve entered valid info. Check for any missing fields and try again')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                } else if (response.status === 500) {
                    // Display modal or handle 500 (Internal Server Error) differently
                    console.error('Internal server error:', errorData);
                    setIsModalOpen(true);
                    setTitle('Something Went Wrong');
                    setDescription('We apologize for the inconvenience. We\'re working to resolve the issue. Please try submitting your information again in a few minutes. If the issue persists, contact us for assistance.')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                    // Call your modal opening function here (optional)
                    // handleServerError(errorData); // Replace with custom error handling
                } else {
                    // Handle unexpected errors
                    console.error('Unexpected error:', errorData);
                    setTitle('Something Went Wrong');
                    setDescription('We apologize for the inconvenience. We\'re working to resolve the issue. Please try submitting your information again in a few minutes. If the issue persists, contact us for assistance.')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                    // Consider displaying a generic error message or providing fallback behavior
                }
                throw new Error('API request failed'); // Re-throw to trigger `finally` block
            }

            const data = await response.json();
            // navigate("/application/academic-background");
            setDataSaved(true);
            setLoading(false);

            return response;
        } catch (error) {
            console.log(`Error: ${error}`);
        } finally {
            setLoading(false);
        }
    }

    const submitPersonalInfo = async () => {
        const token = localStorage.getItem('userToken');
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants`;
        setLoading(true);
        const formData = new FormData();

        let personalInfoDto = {
            id: id,
            firstName,
            lastName,
            middleName,
            gender,
            dateOfBirth,
            city,
            address,
            passportNumber,
            passportDateOfExpire,
            phone,
            email,
            emergencyContactNumber
        };

        let dataToSend = {
            id: applicantId,
            personalInfoDto,
        };

        formData.append('applicantDto', JSON.stringify(dataToSend));

        if (uploadedPassportFile) formData.append('passportFile', uploadedPassportFile);
        if (uploadedAttestatFile) formData.append('attestatFile', uploadedAttestatFile);
        handleSnackbarOpen();
        try {
            const response = await fetch(url, {
                method: 'PATCH',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                // Handle errors based on status code
                const errorData = await response.json(); // Parse error response
                if (response.status === 400) {
                    // Display modal for 400 (Bad Request) errors
                    console.error('Bad request:', errorData);
                    // Call your modal opening function here
                    setIsModalOpen(true);// Replace with your modal logic
                    setTitle('Invalid input');
                    setDescription('Please check for errors in fields. Ensure that you\'ve entered valid info. Check for any missing fields and try again')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                } else if (response.status === 500) {
                    // Display modal or handle 500 (Internal Server Error) differently
                    console.error('Internal server error:', errorData);
                    setIsModalOpen(true);
                    setTitle('Something Went Wrong');
                    setDescription('We apologize for the inconvenience. We\'re working to resolve the issue. Please try submitting your information again in a few minutes. If the issue persists, contact us for assistance.')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                    // Call your modal opening function here (optional)
                    // handleServerError(errorData); // Replace with custom error handling
                } else {
                    // Handle unexpected errors
                    console.error('Unexpected error:', errorData);
                    setTitle('Something Went Wrong');
                    setDescription('We apologize for the inconvenience. We\'re working to resolve the issue. Please try submitting your information again in a few minutes. If the issue persists, contact us for assistance.')
                    setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
                    // Consider displaying a generic error message or providing fallback behavior
                }
                throw new Error('API request failed'); // Re-throw to trigger `finally` block
            }

            const data = await response.json();
            // navigate("/application/academic-background");
            setDataSaved(true);
            setLoading(false);
        } catch (error) {
            console.log(`Error: ${error}`);
        } finally {
            setLoading(false);
        }
    }

    const {getRootProps: getRootProps1, getInputProps: getInputProps1} = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf',
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const fileType = file.type
            const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            if (validTypes.includes(fileType)) {
                setUploadedFile1(file);
                handleFormChange();
            } else {
                setIsModalOpen(true);
                setTitle('Invalid file type');
                setDescription('File was rejected. Invalid file format. Only JPEG, PNG, PDF file types are accepted. Please try again');
                setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
            }
        },
    });

    const {getRootProps: getRootProps2, getInputProps: getInputProps2} = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf',
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const fileType = file.type
            const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            if (validTypes.includes(fileType)) {
                setUploadedFile2(file);
                handleFormChange();
            } else {
                setIsModalOpen(true);
                setTitle('Invalid file type');
                setDescription('File was rejected. Invalid file format. Only JPEG, PNG, PDF file types are accepted. Please try again');
                setIcon(<WarningIcon sx={{fontSize: 50, color: 'orange'}}/>)
            }
        },
    });

    const handleDeleteFile1 = () => {
        setUploadedFile1(null);
    };

    const handleDeleteFile2 = () => {
        setUploadedFile2(null);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true);
    };

    const handleNext = async () => {
        if (isFormChanged) {
            const apiResponse = await submitPersonalInfoV1();
            if (apiResponse && apiResponse.ok) {
                navigate("/application/academic-background");
            }
        } else {
            navigate("/application/academic-background");
        }
    };
    const handleFormChange = () => {
        setFormChange(true);
    }

    const handleModalClose = () => {
        // Reset state and navigate away (replace '/login' with your actual login route)
        setIsModalOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function getStepContent() {
        return (
            <div className={`${(status === 'APPROVED' || status === 'REJECTED') && 'disabled'}`}>
                <Typography variant="h6" gutterBottom>
                    Personal Information
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="firstName"
                            name="firstName"
                            label="First name"
                            fullWidth
                            autoComplete="given-name"
                            variant="standard"
                            value={firstName}
                            onChange={e => {
                                setFirstName(e.target.value);
                                handleFormChange();
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="lastName"
                            name="lastName"
                            label="Last name"
                            fullWidth
                            autoComplete="family-name"
                            variant="standard"
                            value={lastName}
                            onChange={e => {
                                setLastName(e.target.value);
                                handleFormChange();
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="Middle Name"
                            name="middlename:"
                            label="Middle Name"
                            fullWidth
                            variant="standard"
                            value={middleName}
                            onChange={e => {
                                setMiddleName(e.target.value);
                                handleFormChange();
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="gender"
                            name="gender"
                            label="Gender"
                            fullWidth
                            select
                            variant="standard"
                            value={gender}
                            onChange={e => {
                                setGender(e.target.value);
                                handleFormChange();
                            }}
                        >
                            <MenuItem value="MALE">Male</MenuItem>
                            <MenuItem value="FEMALE">Female</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="dob"
                            name="dob"
                            label="Date of Birth"
                            fullWidth
                            type="date"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={dateOfBirth}
                            onChange={e => {
                                setDateOfBirth(e.target.value);
                                handleFormChange();
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="City:"
                            name="city:"
                            label="City"
                            fullWidth
                            select
                            variant="standard"
                            value={city}
                            onChange={e => {
                                setCity(e.target.value);
                                handleFormChange();
                            }}
                        >
                            <MenuItem value="Tashkent City">Tashkent City</MenuItem>
                            <MenuItem value="Tashkent Region">Tashkent Region</MenuItem>
                            <MenuItem value="Samarqand">Samarqand</MenuItem>
                            <MenuItem value="Navoiy">Navoiy</MenuItem>
                            <MenuItem value="Buxoro">Buxoro</MenuItem>
                            <MenuItem value="Sirdaryo">Sirdaryo</MenuItem>
                            <MenuItem value="Jizzax">Jizzax</MenuItem>
                            <MenuItem value="Xorazm">Xorazm</MenuItem>
                            <MenuItem value="Surxondaryo">Surxondaryo</MenuItem>
                            <MenuItem value="Namangan">Namangan</MenuItem>
                            <MenuItem value="Andijon">Andijon</MenuItem>
                            <MenuItem value="Qashqadaryo">Qashqadaryo</MenuItem>
                            <MenuItem value="Fargʻona">Fargʻona</MenuItem>
                            <MenuItem value="Qoraqalpogʻiston">Qoraqalpogʻiston </MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="cityProvince"
                            name="cityProvince"
                            label="Address"
                            fullWidth
                            variant="standard"
                            value={address}
                            onChange={e => {
                                setAddress(e.target.value);
                                handleFormChange();
                            }}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="email:"
                            name="Email Address"
                            label="Email Address"
                            fullWidth
                            variant="standard"
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                                handleFormChange();
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="serialNumber"
                            name="serialNumber"
                            label="Passport/ID Card Serial Number"
                            fullWidth
                            variant="standard"
                            value={passportNumber}
                            onChange={e => {
                                setPassportNumber(e.target.value);
                                handleFormChange();
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="doe"
                            name="doe"
                            label="Date of Expire"
                            fullWidth
                            type="date"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={passportDateOfExpire}
                            onChange={e => {
                                setPassportDateOfExpire(e.target.value);
                                handleFormChange();
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridItem">
                        <div className="inputWithLabel">
                            <label htmlFor="images">Passport/ID Card*</label>
                            <br/>
                            <p style={{color: "red", fontSize: "15px"}}>(Only JPEG, PNG, PDF file types are accepted)</p>
                            {(passportStatus !== "SUBMITTED" && passportStatus !== "APPROVED") && (
                                <div {...getRootProps1()} className="dropzone">
                                    <input {...getInputProps1()} required/>
                                    {uploadedPassportFile ? (
                                        <div>
                                            {uploadedPassportFile.type.startsWith('image/') ? (
                                                <img
                                                    src={URL.createObjectURL(uploadedPassportFile)}
                                                    alt="Uploaded"
                                                    className="uploadedImage"
                                                />
                                            ) : (
                                                <div className="fileInfo">
                                                    <p>{uploadedPassportFile.name}</p>
                                                </div>
                                            )}
                                            <button className="deleteButton" onClick={handleDeleteFile1}>
                                                <DeleteIcon/>
                                            </button>
                                        </div>
                                    ) : (
                                        <p>Drag & drop or click to select a file</p>
                                    )}
                                </div>
                            )}
                            {passportStatus === "SUBMITTED" &&
                                <p>
                                    <CheckCircleOutlineIcon sx={{color: 'orange'}}/> Your submitted file is waiting for confirmation.
                                </p>
                            }
                            {passportStatus === "APPROVED" &&
                                <p>
                                    <CheckCircleOutlineIcon sx={{color: 'green'}}/> Your submitted file has been approved.
                                </p>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridItem">
                        <div className="inputWithLabel">
                            <label htmlFor="images">Diploma (Attestat yoki Ma'lumotnoma)*</label>
                            <br/>
                            <p style={{color: "red", fontSize: "15px"}}>(Only JPEG, PNG, PDF file types are accepted)</p>
                            {(attestatStatus !== "SUBMITTED" && attestatStatus !== "APPROVED") && (
                                <div {...getRootProps2()} className="dropzone">
                                    <input {...getInputProps2()} required/>
                                    {uploadedAttestatFile ? (
                                        <div>
                                            {uploadedAttestatFile.type.startsWith('image/') ? (
                                                <img
                                                    src={URL.createObjectURL(uploadedAttestatFile)}
                                                    alt="Uploaded"
                                                    className="uploadedImage"
                                                />
                                            ) : (
                                                <div className="fileInfo">
                                                    <p>{uploadedAttestatFile.name}</p>
                                                </div>
                                            )}
                                            <button className="deleteButton" onClick={handleDeleteFile2}>
                                                <DeleteIcon/>
                                            </button>
                                        </div>
                                    ) : (
                                        <p>Drag & drop or click to select a file</p>
                                    )}
                                </div>
                            )}
                            {attestatStatus === "SUBMITTED" &&
                                <p>
                                    <CheckCircleOutlineIcon sx={{color: 'orange'}}/> Your submitted file is waiting for
                                    confirmation.
                                </p>
                            }
                            {attestatStatus === "APPROVED" &&
                                <p>
                                    <CheckCircleOutlineIcon sx={{color: 'green'}}/> Your submitted file has been
                                    approved.
                                </p>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridItem">
                        <div className="inputWithLabel">
                            <label htmlFor="phoneNumber">Phone Number*</label>
                            <PhoneInput
                                required
                                id="phoneNumber"
                                name="phoneNumber"
                                label="Phone Number"
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="UZ"
                                value={phone}
                                onChange={phone => {
                                    setPhoneNumber(phone);
                                    handleFormChange();
                                }}
                                country={(props) => (
                                    <div>
                                        <img
                                            src={`https://flag-icon-css.lip.is/flags/4x3/${props.country.toLowerCase()}.svg`}
                                            alt={props.country}
                                            style={{width: '20px', marginRight: '5px'}}
                                        />
                                        {props.country}
                                    </div>
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} className="GridItem">
                        <div className="inputWithLabel">
                            <label htmlFor="emergencyContactNumber">Emergency contact number(Parents)*</label>
                            <PhoneInput
                                id="emergencyContactNumber"
                                name="emergencyContactNumber"
                                label="Emergency contact number"
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry="UZ"
                                value={emergencyContactNumber}
                                onChange={number => {
                                    setEmergencyContactNumber(number);
                                    handleFormChange();
                                }}
                                country={(props) => (
                                    <div>
                                        <img
                                            src={`https://flag-icon-css.lip.is/flags/4x3/${props.country.toLowerCase()}.svg`}
                                            alt={props.country}
                                            style={{width: '20px', marginRight: '5px'}}
                                        />
                                        {props.country}
                                    </div>
                                )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
    return (
        <React.Fragment>
            <CssBaseline/>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
            </AppBar>
            <Container component="main" maxWidth="md" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
                    <Typography component="h1" variant="h4" align="center">
                        Admission Application
                    </Typography>
                    {status === 'APPROVED' && <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className='approved'>
                            <Typography id="modal-modal-title" variant="h4" component="h1" style={{fontWeight: '500'}}>
                                Application Status
                            </Typography>
                            <Typography className='approved-text' variant="h6" id="modal-modal-description" sx={{ mt: 2 }}>
                                Your application has successfully been approved.✅
                            </Typography>
                            <Button onClick={handleClose} variant="contained" color="success" sx={{ mt: 2 }}>
                                OK
                            </Button>
                        </Box>
                    </Modal>
                    }
                    {status === 'REJECTED' && <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className='approved'>
                            <Typography id="modal-modal-title" variant="h4" component="h1" style={{fontWeight: '500'}}>
                                Application Status
                            </Typography>
                            <Typography className='approved-text' variant="h6" id="modal-modal-description" sx={{ mt: 2 }}>
                                Your application has been rejected.❌
                            </Typography>
                            <Button onClick={handleClose} variant="contained" color="success" sx={{ mt: 2 }}>
                                OK
                            </Button>
                        </Box>
                    </Modal>
                    }
                    <Box sx={{mb: 2, mt: 2}}>
                        <Stepper sx={{width: '100%'}} alternativeLabel>
                            <Step key="personal-details">
                                <StepLabel>Personal Details</StepLabel>
                            </Step>
                            <Step key="academic-background">
                                <StepLabel>Academic Background</StepLabel>
                            </Step>
                            <Step key="confirmation">
                                <StepLabel>Confirmation</StepLabel>
                            </Step>
                        </Stepper>
                    </Box>
                    {getStepContent()}
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 3}}>
                        {/*<Button*/}
                        {/*    variant="contained"*/}
                        {/*    color="success"*/}
                        {/*    size="small"*/}
                        {/*    startIcon={<SaveIcon/>}*/}
                        {/*    onClick={submitPersonalInfo}*/}
                        {/*    sx={{ml: 1}}*/}
                        {/*>*/}
                        {/*    Save*/}
                        {/*</Button>*/}
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={handleNext}
                            disabled={!allFieldsFilled}
                            sx={{ml: 1}}
                        >
                            Next
                        </Button>
                    </Box>
                </Paper>
                <Box mt={3}>
                    <CopyrightForm/>
                </Box>
            </Container>
            <Snackbar
                variant="solid"
                color="success"
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                autoHideDuration={2000}
                startDecorator={<PlaylistAddCheckCircleRoundedIcon/>}
            >
                Your data has been saved successfully.
            </Snackbar>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <SpringModal
                open={isModalOpen}
                handleClose={handleModalClose}
                title={title}
                description={description}
                icon={icon}
            />
        </React.Fragment>
    );
}