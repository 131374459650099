import React, {useState} from 'react';
import './UnderConstruction.css';
import {useNavigate} from "react-router-dom";

function UnderConstruction() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const navigate = useNavigate();

    const handleLogin = (event) => {
        event.preventDefault();
        if (username === 'admin' && password === 'admin024') {
            setIsLoggedIn(true);
            navigate('/login');
        } else {
            alert('Invalid username or password!');
        }
    };

    return (
        <div className="under-construction">
            <div className="uc-container">
                {!isLoggedIn && showLogin && (
                    <form className="uc-form" onSubmit={handleLogin}>
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button className="uc-button" type="submit">Login</button>
                    </form>
                )}
                {!isLoggedIn && !showLogin && (
                    <div>
                        <h3 className="uc-message">This is a development page</h3>
                        <br/>
                        <h3>For university applications, please visit:</h3>
                        <h4>
                            <a href="https://admission.millatumidi.uz/" target="_blank" rel="noopener noreferrer">
                                admission.millatumidi.uz
                            </a>
                        </h4>
                        <a className="uc-login-link" href="#" onClick={() => setShowLogin(true)}>
                            For Authorized Users
                        </a>
                    </div>
                )}
                {isLoggedIn && <p>You are now logged in!</p>}
            </div>
        </div>
    );
}

export default UnderConstruction;