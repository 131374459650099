import Modal from 'react-modal';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Document, Page } from 'react-pdf';
import ImageZoom from '../AdminComponents/ImageZoom';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { Box, Button, ButtonGroup } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import "react-pdf/dist/esm/Page/TextLayer.css";

const FileModal = ({ isModalOpen, closeModal, filePath }) => {
    const [rotate, setRotate] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(1);
    const [numPages, setNumPages] = useState(null);

    const handleZoomIn = () => {
        setZoomLevel(zoomLevel + 0.1);
    };

    const handleZoomOut = () => {
        setZoomLevel(zoomLevel - 0.1);
    };
    const handleZoomReset = () => {
        setRotate(0);
        setZoomLevel(1);
    };
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div className="file-modal" style={{ marginTop: "20px" }}>
            {
                isModalOpen && <>
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                zIndex: 1000,
                            },
                            content: {
                                width: '60%',
                                left: '10',
                                right: 'auto',
                                margin: '20px',
                                padding: 20,
                                borderRadius: 8,
                            },
                        }}
                    >
                        {
                            filePath ? (
                                (filePath?.endsWith('.pdf') || filePath?.endsWith('.PDF')) ? (
                                    <>
                                        <ButtonGroup
                                            sx={{ position: "fixed", zIndex: 10010 }}
                                            variant="contained"
                                            aria-label="Basic button group"
                                        >
                                            <Button onClick={() => handleZoomIn()}>+</Button>
                                            <Button onClick={() => handleZoomOut()}>-</Button>
                                            <Button onClick={()=> handleZoomReset()}>Reset</Button>
                                            <Button onClick={() => setRotate(rotate - 90)}>
                                                <RefreshIcon />
                                            </Button>
                                        </ButtonGroup>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Document
                                                file={`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/files?filePath=` + filePath}
                                                onLoadError={(error) => console.log('Document Load Error:', error)}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                                rotate={rotate}
                                                renderAnnotationLayer={false}
                                            >

                                                {numPages && Array.from({ length: numPages }, (_, index) => (
                                                    <Page key={index + 1} pageNumber={index+1} scale={zoomLevel} rotate={rotate}/>
                                                ))}
                                            </Document>
                                        </Box>
                                    </>
                                ) : (
                                    <ImageZoom filePath={filePath}/>
                                )
                            ) : <Typography sx={{ display: 'flex', justifyContent: 'center', mt:'4px'}}>File not submitted</Typography>
                        }

                        <button className="close-button" onClick={closeModal}
                                style={{
                                    position: 'absolute',
                                    top: 10,
                                    right: 8,
                                    cursor: 'pointer'
                                }}>
                            <CloseRoundedIcon fontSize="large"/>
                        </button>
                    </Modal>
                </>
            }
        </div>
    )
}

export default FileModal;