import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Modal, Button, IconButton } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import muLogo from "../../assets/images/single-logo.svg";

const UploadFile = () => {
    const [uploadedPassportFile, setUploadedFile1] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [icon, setIcon] = useState(null);

    let params = (new URL(document.location)).searchParams;
    let applicantUUID = params.get("uuid");

    const handleClose = () => setIsModalOpen(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf',
        maxSize: 5 * 1024 * 1024,
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            const fileType = file.type;
            const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            if (validTypes.includes(fileType)) {
                setUploadedFile1(file);
                setFilePreview(URL.createObjectURL(file));
            } else {
                setIsModalOpen(true);
                setTitle('Invalid file type');
                setDescription('File was rejected. Invalid file format. Only JPEG, PNG, PDF file types are accepted. Please try again');
                setIcon(<WarningIcon sx={{ fontSize: 50, color: 'orange' }} />);
            }
        },
    });

    const handleDelete = () => {
        setUploadedFile1(null);
        setFilePreview(null);
    };

    const handleSave = async () => {
        if (!uploadedPassportFile) return;

        try {
            const formData = new FormData();
            formData.append('paymentReceiptFile', uploadedPassportFile);
            formData.append('applicantUUID', applicantUUID);

            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/files/applicant/payment-receipt`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setIsModalOpen(true);
                setTitle('Upload Successful');
                setDescription('Your file has been uploaded successfully.');
                setIcon(<CheckCircleIcon sx={{ fontSize: 50, color: 'green' }} />);
                setUploadedFile1(null);
                setFilePreview(null);
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            setIsModalOpen(true);
            setTitle('Upload failed');
            setDescription('There was an error uploading the file. Please try again.');
            setIcon(<WarningIcon sx={{ fontSize: 50, color: 'orange' }} />);
        }
    };

    return (
        <Box style={{marginBottom: "10px"}}>
            <Box {...getRootProps()} sx={{ border: '2px dashed rgb(193, 143, 99)', padding: '20px', textAlign: 'center' }}>
                <input {...getInputProps()} />
                {!uploadedPassportFile ? (
                    <>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <img className="mu-mobile-logo" style={{width: "25px", height: "25px", marginRight: '10px'}}
                                 src={muLogo}
                                 alt="MU University Logo"/>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Typography variant="h6" className="university-name university-name-mobile"
                                            style={{margin: 0, fontWeight: 'bold'}}>MU University /
                                </Typography>
                                <div>
                                    <Typography variant="h6" className="university-name-mobile"
                                                style={{marginLeft: "10px", fontWeight: 'bold', color: "#333" }}>
                                        Upload your receipt*
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <Typography className="upload-text-mobile" variant="h6">Drag & drop a file here, or click to select one</Typography>
                        <Typography variant="body2">(Only *.jpeg, *.png, *.pdf files will be accepted)</Typography>
                    </>
                ) : (
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2}}>
                        {uploadedPassportFile.type.startsWith('image/') ? (
                            <img src={filePreview} alt="preview" style={{maxWidth: '100%', maxHeight: '200px'}}/>
                        ) : (
                            <iframe
                                src={filePreview}
                                title="preview"
                                style={{width: '100%', height: '200px', border: 'none'}}
                            />
                        )}
                        <IconButton onClick={handleDelete} sx={{color: 'red', marginTop: 2}}>
                            <DeleteIcon style={{fontSize: 40}}/>
                        </IconButton>
                    </Box>
                )}
            </Box>

            {uploadedPassportFile && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            )}
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="muodal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Box sx={{ textAlign: 'center' }}>
                        {icon}
                        <Typography id="modal-title" variant="h6" component="h2">{title}</Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>{description}</Typography>
                        <Button onClick={handleClose} sx={{ mt: 2 }}>Close</Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default UploadFile;
