import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import SignUp from '../components/Authentication/signup';
import Login from '../components/Authentication/login';
import ForgotPassword from '../components/Authentication/forgot-password';
import AdminV2 from "../components/Admin/AdminV2";
import CallOperators from "../components/CallOperators/callOperators";
import Statistics from '../components/AdminComponents/StatisticsPage/statistics';
import PersonalDetailsForm from "../components/Steps-Info/PersonalDetailsForm";
import EducationBackgroundForm from "../components/Steps-Info/EducationBackgroundForm";
import ConfirmationForm from "../components/Steps-Info/ConfirmationForm";
import SubmissionMessageForm from "../components/Steps-Info/SubmissionMessageForm";
import Contract from '../components/Contract/contract';
import PrivateRoute from "./PrivateRoute";
import UnderConstruction from "../components/utils/UnderConstruction";
import UserForm from "../components/utils/UserForm";

const AppRoutes = () => {
    return (
        <Routes>
            <Route
                path="/admin"
                element={
                    <PrivateRoute roles={["ADMIN", "SUPER_ADMIN", "CALL_CENTER"]}>
                        <AdminV2/>
                    </PrivateRoute>
                }
            />
            <Route
                path="/statistics"
                element={
                    <PrivateRoute roles={["ADMIN", "SUPER_ADMIN"]}>
                        <Statistics/>
                    </PrivateRoute>
                }
            />
            <Route
                path="/call-operators"
                element={
                    <PrivateRoute roles={["CALL_CENTER", "SUPER_ADMIN"]}>
                        <CallOperators/>
                    </PrivateRoute>
                }
            />
            <Route
                path="/application/personal-details"
                element={
                    <PrivateRoute roles={["APPLICANT"]}>
                        <PersonalDetailsForm/>
                    </PrivateRoute>
                }
            />
            <Route
                path="/application/academic-background"
                element={
                    <PrivateRoute roles={["APPLICANT"]}>
                        <EducationBackgroundForm/>
                    </PrivateRoute>
                }
            />
            <Route
                path="/application/confirmation"
                element={
                    <PrivateRoute roles={["APPLICANT"]}>
                        <ConfirmationForm/>
                    </PrivateRoute>
                }
            />
            <Route
                path="/application/submission-message"
                element={
                    <PrivateRoute roles={["APPLICANT"]}>
                        <SubmissionMessageForm/>
                    </PrivateRoute>
                }
            />
            <Route path="*" element={<PrivateRoute />} />

            <Route path="/" element={<UnderConstruction/>}/>
            <Route path="/register" element={<SignUp/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/forgot-password" element={<ForgotPassword/>}/>
            <Route path="applicant/contract" element={<Contract/>}/>
        </Routes>
    );
};

export default AppRoutes;