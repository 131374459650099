import React, {useState} from 'react';
import Button from '@mui/material/Button';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import CopyrightForm from "../Steps-Info/CopyrightForm";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
const defaultTheme = createTheme();

export default function SignInSide() {
    const [login, setlogin] = useState("");
    const [password, setpassword] = useState("");
    const [alert, setAlert] = useState({type: '', message: ''});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    let params = (new URL(document.location)).searchParams;
    let callCenterRefID = params.get("reference");

    const handleSubmit = (e) => {
        e.preventDefault();
        let obj = {login, password, callCenterRefID};

        setLoading(true);

        fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/users/sign-in`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((json) => {
                        setLoading(false);
                        throw new Error(json.message || `Failed: ${res.status}`);
                    });
                }
            })
            .then((data) => {
                if (data.success) {
                    const token = data.data.token;
                    const userRole = data.data.userRole;
                    localStorage.setItem('userToken', token);
                    localStorage.setItem('userRole', userRole);
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    };

                    if (data.errors && data.errors.length > 0) {
                        setLoading(false);
                        setAlert({type: 'error', message: data.errors[0].error || 'Failed: Please try again!'});
                    } else {
                        setAlert({type: 'success', message: 'Login Successful!'});

                        setTimeout(() => {
                            if (userRole === 'APPLICANT') {
                                navigate('/application/personal-details');
                            }
                            if (userRole === 'ADMIN') {
                                navigate('/admin');
                            }
                            if (userRole === 'SUPER_ADMIN') {
                                navigate('/admin');
                            }
                            if (userRole === 'SUPER_ADMIN') {
                                navigate('/call-operators');
                            }
                            if (userRole === 'CALL_CENTER') {
                                navigate('/call-operators');
                            }
                        }, 1500);
                    }
                } else {
                    setLoading(false);
                    setAlert({type: 'error', message: data.message || 'Failed: Please try again!'});
                }
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
                setAlert({type: 'error',  message: err.message || 'Failed: Please try again!' });
            });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{height: '100vh'}}>
                <CssBaseline/>
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://dev.my.millatumidi.uz/static/media/university-logo.eff4b3c9.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img src={require("../../assets/images/mu-logo.png")} alt="logo"
                             style={{width: '200px', height: '100%', marginBottom: '1rem'}}/>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        {alert.type && (
                            <Alert severity={alert.type} sx={{mb: 2}}>
                                {alert.message}
                            </Alert>
                        )}
                        <Box component="form" noValidate onSubmit={handleSubmit} onKeyDown={handleKeyDown} sx={{mt: 1}}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="login"
                                label="Login"
                                name="login"
                                type="email"
                                autoComplete="email"
                                autoFocus
                                value={login}
                                sx={{ mt: 3, mb: 1 }} // Increase margin top to push the label up
                                onChange={e => setlogin(e.target.value.toLowerCase())}
                                onKeyPress={(e) => {
                                    if (e.charCode === 32) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                value={password}
                                sx={{ mt: 3, mb: 1 }} // Increase margin top to push the label up
                                onChange={e => setpassword(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleTogglePasswordVisibility}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onKeyPress={(e) => {
                                    if (e.charCode === 32) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                            <Button
                                // component={RouterLink}
                                // to="/application"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                }}
                                onClick={handleSubmit}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="/forgot-password" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="/" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                            <CopyrightForm sx={{mt: 5}}/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

        </ThemeProvider>
    );
}
