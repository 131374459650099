import React from 'react';
import Button from '@mui/material/Button';

const DownloadFile = () => {
    let params = (new URL(document.location)).searchParams;
    let applicantUUID = params.get("uuid");

    const handleDownload = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/files/applicant/contract/${applicantUUID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                },
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'contract.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Failed to download file');
            }
        } catch (error) {
            console.error('Error while downloading file:', error);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "20px"}}>
            <Button
                variant="contained"
                onClick={handleDownload}
                sx={{
                    backgroundColor: 'rgb(193, 143, 99)',
                    '&:hover': {
                        backgroundColor: 'rgb(173, 123, 79)',
                    },
                    color: '#fff',
                    padding: '10px 20px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    width: "100%"
                }}
            >
                Download PDF Contract
            </Button>
        </div>
    );
};

export default DownloadFile;
