import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import CopyrightForm from "../Steps-Info/CopyrightForm";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SpringModal from "../utils/SpringModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {MenuItem} from "@mui/material";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const defaultTheme = createTheme();

export default function SignInSide() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Sign Up', 'Personal Details', 'Academic Background', 'Confirmation'];
    const [firstname, setfirstname] = useState("");
    const [lastname, setlastname] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");
    const [passportNumber, setpassportNumber] = useState("");
    const [alert, setAlert] = useState({ type: '', message: '' });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const location = useLocation();
    const passportRegex = /^[A-Za-z]{2}\d{7}$/;
    const phoneRegex = /\+998\d{9}/;
    const emailRegex = /^[a-zA-Z0-9]([\w\.-]*[a-zA-Z0-9])*@[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])*(\.[a-zA-Z]{2,6})+$/;

    const [errors, setErrors] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        passportNumber: ''
    });

    let params = (new URL(document.location)).searchParams;
    // let refID = params.get("ref");
    let refID = params.get("refid");
    let callCenterRefID = params.get("reference");

    const validate = () => {
        let tempErrors = {};
        tempErrors.firstname = firstname ? '' : 'First name is required';
        tempErrors.lastname = lastname ? '' : 'Last name is required';
        tempErrors.email = email ? (emailRegex.test(email) ? '' : 'Email is not valid') : 'Email is required';
        tempErrors.phone = phone ? (phoneRegex.test(phone) ? '' : 'Please enter a valid phone number') : 'Phone number is required';
        tempErrors.passportNumber = passportNumber ? (passportRegex.test(passportNumber) ? '' : 'Please enter a valid passport number') : 'Passport number is required';
        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === '');
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted');
        setLoading(true);

        if (!validate()) {
            setLoading(false);
            return;
        }

        let obj = { firstname, lastname, email, phone, passportNumber, refID, callCenterRefID };

        fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/users/sign-up`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj)
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }

            return res.json().then(json => {
                if (json.code === 400) {
                    setLoading(false);
                    throw new Error(json.message);
                } else {
                    throw new Error(json.message || `Failed: ${res.status}`);
                }
            });
        })
            .then((data) => {
                console.log(data);
                setAlert({ type: 'success', message: 'Registered successfully, please check your email!' });
                setOpen(true);
                setTimeout(() => {
                    // navigate('/login');
                }, 1500);
            })
            .catch((err) => {
                console.error(err);
                setAlert({ type: 'error', message: err.message });
            });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const refid = searchParams.get('refid');
        if (refid) {
            logReferralVisit(refid);
        }
    }, [location]);

    const logReferralVisit = (ref) => {
        const apiUrl = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/users/ref/visit/counter?refid=${encodeURIComponent(ref)}&&type=${encodeURIComponent('admission')}`;
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                console.log('Referral logged:', data);
            })
            .catch(error => {
                console.error('Error logging referral:', error);
            });
    };

    const handleModalClose = () => {
        setOpen(false);
        navigate('/login');
    };

    const handleChangeEmail = (e) => {
        const value = e.target.value;
        if (!/\s/.test(value)) {
            setemail(value.toLowerCase());
        }
    };

    const handleChangePhone = (e) => {
        const value = e.target.value;
        const regex = /^[0-9+]*$/;
        if (!/\s/.test(value) && regex.test(value)) {
            setphone(value);
        }
    };

    const handleVideoGuideClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://dev.my.millatumidi.uz/static/media/university-logo.eff4b3c9.png)',
                        backgroundRepeat: 'no-repeat',
                        objectFit: "cover",
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 2,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                        <Grid container justifyContent="flex-end" style={{marginBottom: "15px"}}>
                            <Grid item>
                                <Link
                                    className="mobile-video"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleVideoGuideClick}
                                    variant="body2"
                                    style={{
                                        color: '#1976d2',
                                        textDecoration: 'none',
                                        padding: '17px',
                                        borderRadius: '4px',
                                        border: '1px solid #1976d2',
                                        transition: 'all 0.3s',
                                        fontWeight: 'bold',
                                        cursor: 'pointer',
                                    }}
                                    onMouseEnter={(e) => {
                                        e.target.style.backgroundColor = '#1976d2';
                                        e.target.style.color = '#fff';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.style.backgroundColor = 'transparent';
                                        e.target.style.color = '#1976d2';
                                    }}
                                >
                                    Watch our video guide {anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </Link>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleMenuClose}
                                >
                                    <MenuItem onClick={() => window.open("../../assets/video/registerV2.mp4", "_blank")}>
                                        Register via Website
                                    </MenuItem>
                                    <MenuItem onClick={() => window.open("assets/video/registerTelegram.mp4", "_blank")}>
                                        Register via Telegram
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                        <img src={require("../../assets/images/mu-logo.png")} alt="logo"
                             style={{ width: '200px', height: '100%', marginBottom: '1rem' }} />
                        <Typography component="h1" variant="h5">
                            Sign Up
                        </Typography>
                        {alert.type && (
                            <Alert severity={alert.type} sx={{ mb: 2, mt: 2 }}>
                                {alert.message}
                            </Alert>
                        )}
                        <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%', mb: 4, mt: 4 }}>
                            {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="firstname"
                                        required
                                        fullWidth
                                        id="firstname"
                                        label="First Name"
                                        autoFocus
                                        value={firstname}
                                        onChange={e => setfirstname(e.target.value)}
                                        error={Boolean(errors.firstname)}
                                        helperText={errors.firstname}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastname"
                                        label="Last Name"
                                        name="lastname"
                                        autoComplete="family-name"
                                        value={lastname}
                                        onChange={e => setlastname(e.target.value)}
                                        error={Boolean(errors.lastname)}
                                        helperText={errors.lastname}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={email}
                                        onChange={handleChangeEmail}
                                        onKeyPress={(e) => {
                                            if (e.charCode === 32) {
                                                e.preventDefault();
                                            }
                                        }}
                                        placeholder="example@mail.com"
                                        error={Boolean(errors.email)}
                                        helperText={errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        inputProps={{ maxLength: 13 }}
                                        name="phone"
                                        label="Phone Number"
                                        type="tel"
                                        id="phone"
                                        value={phone}
                                        onChange={handleChangePhone}
                                        onKeyPress={(e) => {
                                            if (e.charCode === 32) {
                                                e.preventDefault();
                                            }
                                        }}
                                        placeholder="+998001234567"
                                        error={Boolean(errors.phone)}
                                        helperText={errors.phone}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="passport"
                                        label="Passport Number"
                                        id="passportNumber"
                                        inputProps={{ maxLength: 9 }}
                                        value={passportNumber}
                                        onChange={(e) => setpassportNumber(e.target.value.toUpperCase())}
                                        onKeyPress={(e) => {
                                            if (e.charCode === 32) {
                                                e.preventDefault();
                                            }
                                        }}
                                        placeholder="AC1234567"
                                        error={Boolean(errors.passportNumber)}
                                        helperText={errors.passportNumber}
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                }}
                            >
                                Sign Up
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link href="/login" variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                            <CopyrightForm sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <SpringModal
                open={open}
                handleClose={handleModalClose}
                title={'Sign Up Successful'}
                description={'Congratulations! You have successfully registered. We have sent your login and password to your email address. Please check your email!'}
                icon={<CheckCircleIcon sx={{ fontSize: 72, color: 'green' }} />}
            />

        </ThemeProvider>
    );
}
