import React from 'react';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ roles, children }) {
    const token = localStorage.getItem("userToken");
    const userRole = localStorage.getItem("userRole");
    const isAuthenticated = !!token;

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    if (roles && roles.includes(userRole)) {
        return children;
    }

    return (
        <div className="error-page">
            <i className="fas fa-exclamation-triangle"></i>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for can't be found</p>
            <a href="/">Go Back to Home</a>
        </div>
    )
}

export default PrivateRoute;