import { useState } from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const ImageZoom = ({ filePath }) => {
  const [rotate, setRotate] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleZoomReset = () => {
    setRotate(0);
    setZoomLevel(1);
  };

  return (
      <>
        <ButtonGroup
            sx={{ position: "fixed", zIndex: 10010 }}
            variant="contained"
            aria-label="Basic button group"
        >
          <Button onClick={() => handleZoomIn()}>+</Button>
          <Button onClick={() => handleZoomOut()}>-</Button>
          <Button onClick={() => handleZoomReset()}>Reset</Button>
          <Button onClick={() => setRotate(rotate - 90)}>
            <RefreshIcon />
          </Button>
        </ButtonGroup>
        <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              transform: `rotate(${rotate}deg) scale(${zoomLevel})`
            }}

        >
          <img
              src={
                  `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/files?filePath=` +
                  filePath
              }
              alt="Document"
              loading="lazy"
          />
        </Box>
      </>
  );
};

export default ImageZoom;