import React, { useState, useEffect } from 'react';
import muLogo from '../../assets/images/single-logo.svg';
import { Modal, Box, Typography, Button, TextField, CircularProgress, Backdrop } from '@mui/material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import VerifiedIcon from "@mui/icons-material/Verified";

const ContractContainer = () => {
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [contractInfo, setContractInfo] = useState(null);
    const [view, setView] = useState('ContractSend');
    const [timer, setTimer] = useState(120);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [isCodeExpired, setIsCodeExpired] = useState(false);
    const [isCodeResent, setIsCodeResent] = useState(false);

    let params = (new URL(document.location)).searchParams;
    let applicantUUID = params.get("uuid");

    useEffect(() => {
        const fetchContractInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/contract/info/${applicantUUID}`);
                if (response.ok) {
                    const data = await response.json();
                    setContractInfo(data.data);
                    if (data.data.isContractSigned) {
                        setView('Verified');
                    }
                } else {
                    console.error('Failed to fetch contract info');
                }
            } catch (error) {
                console.error('Error fetching contract info:', error);
            }
        };

        fetchContractInfo();
    }, [applicantUUID]);

    useEffect(() => {
        let timerInterval;
        if (isTimerRunning) {
            timerInterval = setInterval(() => {
                setTimer((prev) => {
                    if (prev === 1) {
                        clearInterval(timerInterval);
                        setIsTimerRunning(false);
                        setIsCodeExpired(true);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timerInterval);
    }, [isTimerRunning]);

    const formatTimer = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleSendCode = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/verification/send/code/${applicantUUID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setMessage('Verification code sent successfully! Please check your email and phone number for the code.');
                setIsTimerRunning(true);
                setTimer(120);
                setView('ContractVerification');
                setIsCodeResent(true);
            } else {
                setMessage('Failed to send verification code. Please try again.');
            }
            setOpen(true);
        } catch (error) {
            console.error('Error sending verification code:', error);
            setMessage('An error occurred. Please try again.');
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleVerifyCode = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_SERVER_API_URL}/applicants/verification/verify/${applicantUUID}/${code}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const result = await response.json();
            if (response.ok && result.success) {
                setMessage('Verified!');
                setView('Verified');
                setIsTimerRunning(false);
                window.location.reload();
            } else {
                setMessage('Verification failed. Please try again.');
                setOpen(true);
            }
        } catch (error) {
            console.error(error);
            setMessage('An error occurred. Please try again.');
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
        if (isCodeResent && message === 'Verification code sent successfully! Please check your email and phone number for the code.') {
            setView('ContractVerification');
        }
        setIsCodeResent(false);
    };

    const handleVerificationCodeChange = (event) => {
        setCode(event.target.value);
    };

    const handleSendCodeAgain = () => {
        setIsCodeExpired(false);
        handleSendCode();
    };

    const blurPhoneNumber = (phone) => {
        const visiblePart = phone.slice(0, 4);
        const blurredPart = '*'.repeat(phone.length - 8);
        const lastFourDigits = phone.slice(-4);
        return visiblePart + blurredPart + lastFourDigits;
    };

    const blurEmail = (email) => {
        const [username, domain] = email.split('@');
        const visiblePart = username.slice(0, -5);
        const blurredPart = '*'.repeat(username.length - visiblePart.length);
        return visiblePart + blurredPart + '@' + domain;
    };

    return (
        <div style={{ marginTop: "5px", display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: "20px" }} className="banner-second">
            {view === 'ContractSend' && (
                <>
                    <div className="banner-content">
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <img className="mu-mobile-logo" style={{width: "25px", height: "25px", marginRight: '10px'}}
                                 src={muLogo} alt="MU University Logo"/>
                            <div style={{display: "flex", alignItems: "center"}}>
                                {contractInfo ? (
                                    <>
                                        <Typography variant="h6" className="university-name university-name-mobile"
                                                    style={{margin: 0, fontWeight: 'bold'}}>
                                            {contractInfo.firstName} {contractInfo.lastName} /
                                        </Typography>
                                        <Typography variant="h6" className="university-text university-name-mobile"
                                                    style={{
                                                        marginLeft: "10px",
                                                        color: contractInfo.isContractSigned ? "green" : "red"
                                                    }}>
                                            {contractInfo.isContractSigned ? 'Signed' : 'Not Signed'}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography variant="h6" className="university-text"
                                                style={{marginLeft: "10px", color: 'red'}}>Loading...</Typography>
                                )}
                            </div>
                        </div>
                    </div>
                    {contractInfo ? (
                        <>
                            <div style={{display: "flex"}}>
                                <Typography className="contract-info-mobile" style={{
                                    marginRight: "15px",
                                    color: "black"
                                }}>Email: {blurEmail(contractInfo.email)}</Typography>
                                <Typography className="contract-info-mobile"
                                            style={{color: "black"}}>Phone: {blurPhoneNumber(contractInfo.phone)}</Typography>
                            </div>
                        </>
                    ) : (
                        <Typography variant="h6" className="university-text"
                                    style={{marginLeft: "10px", color: 'red'}}>Loading...</Typography>
                    )}
                    <Button
                        className="send-code-button"
                        onClick={handleSendCode}
                        variant="contained"
                        sx={{
                            mt: 1,
                            minWidth: '200px',
                            fontWeight: 'bold',
                            backgroundColor: "rgb(193, 143, 99)",
                            '&:hover': {
                                backgroundColor: "rgb(201, 153, 113)"
                            }
                        }}
                        disabled={loading}
                    >
                        Send Code
                    </Button>
                </>
            )}
            {view === 'ContractVerification' && (
                <>
                    <div className="banner-content" style={{textAlign: 'center'}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <img className="mu-mobile-logo" style={{width: "25px", height: "25px", marginRight: '10px' }} src={muLogo} alt="MU University Logo" />
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography variant="h5" className="university-text" style={{ marginLeft: "10px" }}>
                                    Verification Code
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <TextField
                        id="verification-code"
                        label="Enter Verification Code"
                        variant="outlined"
                        value={code}
                        onChange={handleVerificationCodeChange}
                        sx={{ mt: 4, width: '300px', bgcolor: 'white', borderRadius: 1 }}
                    />
                    <Button
                        className="send-code-button"
                        onClick={handleVerifyCode}
                        variant="contained"
                        sx={{ mt: 2, width: '300px', height: '45px', fontWeight: 'bold', textTransform: 'none', backgroundColor: "rgb(193, 143, 99)",
                            '&:hover': {backgroundColor: "rgb(201, 153, 113)"}}}
                        disabled={loading || isCodeExpired}
                    >
                        Verify Code
                    </Button>
                    {isTimerRunning && (
                        <Typography variant="body2" sx={{ mt: 2 }}>
                            Code expires in {formatTimer(timer)}
                        </Typography>
                    )}
                    {timer === 0 && (
                        <>
                            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                Code Expired!
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ mt: 1, textDecoration: 'underline', cursor: 'pointer', fontWeight: 'bold', color: "primary" }}
                                onClick={handleSendCodeAgain}
                            >
                                Send code again
                            </Typography>
                        </>
                    )}
                </>
            )}
            {view === 'Verified' && (
                <>
                    <div className="banner-content" style={{textAlign: 'center'}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <img className="mu-mobile-logo" style={{width: "25px", height: "25px", marginRight: '10px'}} src={muLogo}
                                 alt="MU University Logo"/>
                            <div style={{display: "flex", alignItems: "center"}}>
                                {contractInfo ? (
                                    <>
                                        <Typography variant="h6" className="university-name university-name-mobile"
                                                    style={{margin: 0, fontWeight: 'bold'}}>
                                            {contractInfo.firstName} {contractInfo.lastName} /
                                        </Typography>
                                        <Typography variant="h6" className="university-text university-name-mobile" style={{
                                            marginLeft: "10px",
                                            color: contractInfo.isContractSigned ? "green" : "red"
                                        }}>
                                            {contractInfo.isContractSigned ? 'Contract  Signed' : 'Not Signed'}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography variant="h6" className="university-text"
                                                style={{marginLeft: "10px", color: 'red'}}>Loading...</Typography>
                                )}
                            </div>
                        </div>
                    </div>
                    {contractInfo && contractInfo.isContractSigned && (
                        <div style={{display: "flex"}}>
                            <Typography className="contract-info-mobile" style={{marginRight: "15px", color: "black"}}>Email: {blurEmail(contractInfo.email)}</Typography>
                            <Typography className="contract-info-mobile" style={{color: "black"}}>Phone: {blurPhoneNumber(contractInfo.phone)}</Typography>
                        </div>
                    )}
                    <div className="banner-content" style={{textAlign: 'center', marginTop: "5px"}}>
                        <Typography style={{color: "green"}} variant="h6">Verified</Typography>
                        <VerifiedIcon sx={{fontSize: 25, color: 'green'}} style={{marginLeft: "5px"}}/>
                    </div>
                </>
            )}
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        borderRadius: '8px',
                        boxShadow: 24,
                        p: 4,
                        textAlign: 'center',
                    }}
                >
                    <ForwardToInboxIcon sx={{ fontSize: 50, color: 'primary.main' }} />
                    <Typography id="modal-title" variant="h6" component="h2" sx={{ mt: 2, fontWeight: 'bold' }}>
                        {message}
                    </Typography>
                    <Button onClick={handleClose} variant="contained" color="primary" sx={{ mt: 2 }}>
                        OK
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default ContractContainer;
