import { Typography, Link } from "@mui/material";

const styles = {
    root: {
        marginTop: '1rem'
    }
};

export default function CopyrightForm() {
    const currentYear = new Date().getFullYear();

    return (
        <div style={styles.root}>
            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://millatumidi.uz/" target="_blank" rel="noopener">
                    Millat Umidi University
                </Link>{' '}
                {currentYear}
            </Typography>
        </div>
    );
}
