import React, { useState } from 'react';
import { FormControl, Button, Typography, Modal } from '@mui/material';
import quizData from './data.json';
import Hat from '../../assets/images/hat.png';

const QuizModel = ({ closeModal }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [error, setError] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('english');

  const handleAnswerSelect = (ball) => {
    setSelectedAnswer(ball);
    setError(false);
  };

  const handleNextQuestion = () => {
    if (selectedAnswer !== null) {
      setScore(score + selectedAnswer);
      setSelectedAnswer(null);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setError(true);
    }
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const question = quizData[selectedLanguage][currentQuestionIndex]?.question;
  const answers = quizData[selectedLanguage][currentQuestionIndex]?.answers || [];

  const determineFaculty = (score) => {
    if (score >= 20 && score <= 35) {
      return 'ELT';
    } else if (score >= 36 && score <= 50) {
      return 'IT';
    } else if (score >= 51 && score <= 65) {
      return 'Business';
    } else if (score >= 66 && score <= 80) {
      return 'ACCA';
    } else {
      return 'Unknown';
    }
  };

  const faculty = determineFaculty(score);

  return (
    <Modal open={true} onClose={closeModal}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px', maxWidth: '90vw', width: '400px' }}>
        <div style={{display: "flex"}}>
          <FormControl className='d-flex align-items-end' fullWidth style={{ marginBottom: '10px' }}>
            <select
            style={{outline: 'none', border: '1px solid rgba(25, 118, 210, 0.5)', borderRadius: '5px'}}
              className='w-25'
              value={selectedLanguage}
              onChange={handleLanguageChange}
            >
              <option value="english">English</option>
              <option value="uzbek">Uzbek</option>
            </select>
          </FormControl>
        </div>
        <Typography variant="h6" className="text-center">Discover Your Academic Destiny!</Typography>
        <hr className="w-75 mx-auto" />
        <Typography variant="h5" gutterBottom>{question}</Typography>
        <div>
          {answers.map((answer, index) => (
            <div key={index} style={{ marginBottom: '5px' }}>
              <Button
                variant="outlined"
                onClick={() => handleAnswerSelect(answer.ball)}
                style={{ width: '100%', color: answer.ball === selectedAnswer ? 'white' : 'inherit', backgroundColor: answer.ball === selectedAnswer ? 'green' : 'inherit' }}
              >
                {answer.text}
              </Button>
            </div>
          ))}
        </div>
        {error && <Typography variant="body1" color="error" style={{ fontWeight: 'bold', textAlign: 'center' }}>Please, select an option.</Typography>}
        {currentQuestionIndex < quizData[selectedLanguage].length ? (
          <div style={{ textAlign: 'center' }}>
            <Typography style={{ marginBottom: '5px' }} variant="body1">Question {currentQuestionIndex + 1} of {quizData[selectedLanguage].length}</Typography>
            <Button variant="contained" onClick={handleNextQuestion}>Next</Button>
          </div>
        ) : (
            <div style={{ textAlign: 'center', backgroundColor: '#fff', padding: '20px', borderRadius: '10px' }}>
              <img src={Hat} style={{ width: "300px", borderRadius: '50%' }} alt="FacultyImage" />
              <Typography variant="h6" style={{ color: '#000', marginTop: '10px' }}>Great News!</Typography>
              <Typography variant="h6" style={{ color: '#000', marginTop: '10px' }}>Meet Your Suitable Faculty:</Typography>
              <Typography variant="h5" style={{ color: '#000', marginTop: '10px', fontWeight: 'bold' }}>{faculty}</Typography>
            </div>
          )}
      </div>
    </Modal>
  );
};

export default QuizModel;