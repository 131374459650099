import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SpringModal from "../utils/SpringModal";

const defaultTheme = createTheme();

export default function ForgotPassword() {
    const [alert, setAlert] = useState({ type: '', message: '' });
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const url = `${process.env.REACT_APP_BACKEND_SERVER_API_URL}/users/password/reset/${email}`;
        try {
            const response = await fetch(url, {
                method: 'POST'
            });
            const data = await response.json();
            if (data.success) {
                setAlert({ type: 'success', message: data.message });
                setOpen(true);
            } else {
                setAlert({ type: 'error', message: data.message });
            }
        } catch (error) {
            console.error('Error:', error);
            setAlert({ type: 'error', message: 'An error occurred while resetting the password.' });
        }
        setLoading(false);
    }

    const handleModalClose = () => {
        setOpen(false);
        navigate('/login');
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };


    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://dev.my.millatumidi.uz/static/media/university-logo.eff4b3c9.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img src={require("../../assets/images/mu-logo.png")} alt="logo"
                             style={{ width: '200px', height: '100%', marginBottom: '1rem' }} />
                        <Typography component="h1" variant="h5" gutterBottom>
                            Forgot Password
                        </Typography>
                        <Typography style={{ fontSize: "16px", fontWeight: "500" }} variant="body1" gutterBottom>
                            Please enter the email address you used to register your account
                        </Typography>
                        {alert.type && (
                            <Alert severity={alert.type} sx={{ mt: 2 }}>
                                {alert.message}
                            </Alert>
                        )}
                        <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                }}
                            >
                                Send New Password
                            </Button>
                            <Grid container justifyContent="center">
                                <Grid item>
                                    <Link component={RouterLink} to="/login" variant="body2">
                                        Back to Sign In
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Grid>
            </Grid>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <SpringModal
                open={open}
                handleClose={handleModalClose}
                title={'Reset Password'}
                description={'We have sent your new password to your email address. Please check your email!'}
                icon={<CheckCircleIcon sx={{ fontSize: 72, color: 'green' }} />}
            />
        </ThemeProvider>
    );
}
